import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import Vicdan from '../images/Vicdan.jpg';

const lList = [
    {
        id: "EN",
        header: "Bio",
        text1: "Vicdan Merter, CPCC, PCC, ACTC",
        text2: "Executive and Team Coach, Facilitator",
        text3: "I have 25 years of experience in various global organizations mainly in consumer goods and tobacco sector in companies such as Unilever &Bestfoods and Imperial Tobacco.  As the C Level HR Director, I was exposed to large-scale and complex change management, mergers& acquisition, strategic business partnering, organizational & leadership development challenges.",
        text4: "My coaching is influenced with my corporate leadership background and passion for learning in the area of systemic leadership, neuroscience, emotional intelligence, mindfulness & coaching. My main expertise is leadership development, team coaching, relationship management, effective communication and change management.",
        text5: "I believe in people potential, and I see my role as a thinking partner and dream companion. I take seriously the uniqueness of every person and my role is to reveal the person to their magnificence from there they do the job they need to do in their life. I facilitate process for them to connect deeply to who they really are in essence and navigate the journey towards who they need to become. Together, we explore ways to access clients' body, mind and emotional wisdom to gain new perspectives and stimulate their courage and creativity. It is possible to transform all areas of their lives- one SHIFT at a time! How they think, feel, and act, as a whole is transformed- not just doing things differently in one area of their lives.",
        text6: "I work with leaders, managers, medium & small business owners providing one to one coaching, team coaching and leadership development workshops. I am a Professional Certified Coach (PCC) by ICF-International Coach Federation and co-active coach (CPCC) certified by CTI-Coaching Training Institute. I am also trained as Organizational Relationship and System Coach (ORSC) by CRR. Recently I became among the first to receive ICF (ACTC) Advanced Certification in Team Coaching; ICF’s recent addition to coaching  profession.",
        text7: "I am passionate about expressive art and Jungian coaching using symbolic thinking, metaphors and archetypal language to hold self-discovery space for my clients. I am also faculty in Jungian Coaching School teaching Coaching Foundations internationally as well as mentoring other coaches in their ICF credentialing journey.",
        text8: "I have co-chaired the Co-active Community Alumni in 2017 and volunteered& led Child right awareness workshops in Rwanda with Humanium, Geneva based Child Sponsorship NGO.",
        text9: "I am married with two children - has BSc in Industrial Engineering."
    },
    {
        id: "TR",
        header: "Bio",
        text1: "Vicdan Merter, CPCC, PCC, ACTC",
        text2: "Yönetici ve Takım Koçu, Fasilitatör",
        text3: "25 yıllık insan kaynakları kariyerimde, özellikle hızlı tüketim malları ve tütün sektöründe olmak üzere Imperial Tobacco ve Unilever & Bestfoods gibi çok uluslu şirketlerde Üst Yönetimde İnsan Kaynakları Direktörü ve Yönetim Kurulu üyesi olarak görev yaptım; büyük ölçekli ve karmaşık değişim yönetimi, birleşme ve satın alma, stratejik iş ortaklığı, organizasyonel ve liderlik geliştirme alanlarında çalıştığım kurumlara katkı sağladım.",
        text4: "Koç olarak varlığım, kurumsal liderlik geçmişimden ve sistemik liderlik, sinirbilim, duygusal zeka, mindfulness, Gestalt uygulayıcı ve Jung koçluğu alanlarındaki öğrenme tutkumdan harmanlanır ve etkilenir. Ana uzmanlığım ve çalışma alanlarım liderlik gelişimi, takım koçluğu, ilişki yönetimi, etkili iletişim ve değişim yönetimidir.",
        text5: "İnsanların potansiyeline inanıyorum ve koç olarak rolümü düşünme partneri, bir yoldaş ve hayal arkadaşı olarak görüyorum. Her insanın benzersizliğini önemli buluyorum ve kişinin kendi otantik ihtişamını kendisine gösterdikten sonra kişinin kendi hayatında yapması gereken dönüşümü gerçekleştirmesi için cesaretlendiriyorum. Kendi özleri ile derinlemesine bağ kurarak; olmak istedikleri kişiye doğru yaptıkları yolculukta onlara eşlik ediyorum. Yeni bakış açıları kazanma, cesaret ve yaratıcılıklarını canlandırmak için müşterilerin beden, zihin ve duygusal bilgeliklerine erişme yollarını birlikte keşfediyoruz. Her seferinde minik bir A-HA anı ile yaşamlarını dönüştürmek mümkün! Bu sayede sadece hayatlarının bir alanında fark yaratmanın ötesinde, bütünsel olarak düşünceleri, duyguları ve yaptıkları dönüşür.",
        text6: "Liderler, yöneticiler, orta ve küçük işletme sahipleri ile bire bir koçluk, takım koçluğu veya liderlik geliştirme atölyeleri yapıyorum. ICF-Uluslararası Koçluk Federasyonu tarafından Profesyonel Sertifikalı Koçum (PCC) ve CTI-Coaches Training Institute tarafından sertifikalandırılmış (CPCC) ünvanlı ko-aktif koçum. Ayrıca CRR tarafından Organizasyonel İlişki ve Sistem Koçu (ORSC) olarak da eğitim aldım ve bunun yanısıra ICF-Uluslararası Koçluk Federasyonun koçluk mesleğine yeni kattığu ACTC- İleri Düzey Takım Koçluğu Sertifikasına sahibim.",
        text7: "Müşterilerime kendini keşfetme alanı sağlamak için sembolik düşünme, metaforlar ve arketipik dil kullanan dışavurumcu sanat ve Jung koçluğu konusunda tutkuluyum. Jungian Coaching School’un uluslararası program liderlerinden biriyim. ICF ünvanlanma yolculuklarında koçlara mentorluk yaparak profesyonel koçluğun yerleşmesine katkıda bulunuyorum.",
        text8: "2017'de Co-active Topluluğuna eş başkanlık yaptım ve Cenevre merkezli STK’sı Humanium ile Ruanda'da Çocuk haklarıyla ilgili bilinçlendirme faaliyetlerinde gönüllü oldum ve atölyelere liderlik ettim.",
        text9: "Evliyim ve iki çocuk annesiyim - Endüstri Mühendisliği lisans derecem var."
    }
];

class Hakkimda extends Component {
    componentDidMount() {
        const text = `Bio: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (  
            <div>
                <div className="PageHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <p>
                        {lItem.text1}
                        <br />
                        {lItem.text2}
                    </p>
                    <p>
                        {lItem.text3}
                    </p>
                    <p>
                    <img
                        src={Vicdan}
                        alt="Vicdan Merter"
                        width="40%"
                        height="auto"
                        align="right"
                        hspace="20"
                        vspace="5"
                    />
                    </p>
                    <p>{lItem.text4}</p>
                    <p>{lItem.text5}</p>
                    <p>{lItem.text6}</p>
                    <p>{lItem.text7}</p>
                    <p>{lItem.text8} <a href="https://www.humanium.org/en/reflections-from-rwanda/"><span className="PageLink">(https://www.humanium.org/en/reflections-from-rwanda/)</span></a> {lItem.text9}</p>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Hakkimda);