import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InlineWidget } from "react-calendly";

import { invokeLogger } from "./actions/account";

class Takvim extends Component {
    componentDidMount() {
        const text = `Takvim: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, görüntülendi`;
        this.props.invokeLogger({ id: "w", text });
    }

    render() {
        return (
            <div>
                <InlineWidget
                prefill={{
                    email: this.props.account.email,
                    firstName: this.props.account.ad,
                    lastName: this.props.account.soyad,
                    name: `${this.props.account.ad} ${this.props.account.soyad}`,
                  }}
                url="https://calendly.com/vicdan-merter/mentor-coaching-sessions/" />
            </div>
        )        
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Takvim);