import React, { Component } from 'react';
import { connect } from 'react-redux';

const lList = [
    {
        id: "EN",
        text1: "Welcome",
        text2: "To make an appointment, please click the 'Calendar' option above."
    },
    {
        id: "TR",
        text1: "Hoşgeldiniz",
        text2: "Randevu almak için lütfen yukarıdaki 'Takvim' seçeneğini tıklayınız."
    }
];

class Giris extends Component {
    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="Giris">
                    {lItem.text1} {this.props.account.ad} {this.props.account.soyad}. {lItem.text2}
                </div>
            </div>
        )        
    }
}

export default connect(
    ({ account }) => ({ account }),
    null
)(Giris);