import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger, sendContactForm } from './actions/account';
import queryString from 'query-string';

const lList = [
    {
        id: "EN",
        header: "Your Contact Form Has Been Received",
        text1: "The contact form you filled in reached us with the information below. We will contact you as soon as possible. Thank you for writing to us.",
        text2: "Subject:",
        text3: "Phone:",
        text4: "E-Mail:"
    },
    {
        id: "TR",
        header: "İletişim Formunuz Alındı",
        text1: "Doldurduğunuz iletişim formu, aşağıda gördüğünüz bilgilerle elimize ulaştı. En kısa zamanda sizinle iletişime geçeceğiz. Bize yazdığınız için teşekkür ederiz.",
        text2: "Konu:",
        text3: "Telefon:",
        text4: "E-Posta:"
    }
];

class ContactFormFiled extends Component {
    state = {
        konu: '',
        ad: '',
        soyad: '',
        tel: '',
        email: '',
        mesaj: ''
    }

    componentDidMount() {
        const parsed = queryString.parse(window.location.search);
        if (parsed && parsed.ileten === "Z") {
            this.setState({
                konu: parsed.konu,
                ad: parsed.ad,
                soyad: parsed.soyad,
                tel: parsed.tel,
                email: parsed.email,
                mesaj: parsed.mesaj
            });
            this.props.sendContactForm({
                id: "w",
                user: this.props.account.visitor.serialNumber,
                ad: parsed.ad,
                soyad: parsed.soyad,
                tel: parsed.tel,
                email: parsed.email,
                konu: parsed.konu,
                mesaj: parsed.mesaj,
                dil: "" });
            localStorage.setItem("userName", parsed.ad);
            localStorage.setItem("userSurname", parsed.soyad);
            localStorage.setItem("userPhone", parsed.tel);
            localStorage.setItem("userEmail", parsed.email);
            localStorage.setItem("userLanguage", this.props.language);
            this.props.changeContactFormSent(true);
            this.props.invokeLogger({ id: "w", text: `ContactForm: ${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ad: ${parsed.ad}, soyad: ${parsed.soyad}, tel: ${parsed.tel}, email: ${parsed.email}, konu: ${parsed.konu}, mesaj: ${parsed.mesaj}` });
        } else {
            this.props.invokeLogger({ id: "w", text: "ContactForm: bilinmeyen bir client tarafından çağırıldı, data yok" });
        }
        window.scrollTo(0, 0);
    }

    render() {  
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="PageItemHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <p>
                        {lItem.text1}
                    </p>
                    <p>
                        {lItem.text2} {this.state.konu}
                    </p>
                    <p>
                        {this.state.mesaj}
                    </p>
                    <p>
                        {this.state.ad} {this.state.soyad}
                    </p>
                    <p>
                        {lItem.text3} {this.state.tel}
                    </p>
                    <p>
                        {lItem.text4} {this.state.email}
                    </p>
                </div>
            </div>
        )        
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger, sendContactForm }
)(ContactFormFiled);