import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import CheckInImg from '../images/checkin.png';

const lList = [
    {
        id: "EN",
        header: "Let's Check-In",
        text1: "When we come together with teams for workshops, team coaching or training, the first thing we do is CHECK-IN. I use it in English, because we use it without Turkish translation due to the use in the airline industry. What is CHECK-IN and how is it done?",
        text2: "In short, we ask a strong question to bring everyone to the area we are in, to make them realize their intention to be where they are, and everyone in turn shares the emotion, thought, desire and/or effect that this question evokes in them. So why do we do this and what good does it do to start working with the team this way? check-in; An invitation to be “here and now” in the “now”. It creates a space where team members can share their original feelings and thoughts. It lets you hear all the voices in the team and see where your own voice stands. It helps us to leave our already existing thoughts and opinions out the door and see what is really going on without judgment. Whatever you're voicing is bothering you, it's easier to throw it behind you! In team dynamics, the situation you are in is normalized, you will not feel alone.",
        text3: "CHECK-IN actually clears the field, opens a white page when starting fresh. This open space forms the basis for your intended creation as a team.",
        text4: "Since each CHECK-in is done around a question, it enables the collective perception, the sounds existing in the system to be heard and therefore the group to see itself, the group becomes a mirror for itself. It gives a snapshot of the emotional area of the group. It reveals the trends, partnerships and different perspectives that exist within the team.",
        text5: "If you ask why I care about the check-in process, our routines have been disrupted due to the Pandemic, and our ground has changed in important areas of our lives such as health, career, family, friendship and physical environment, which form the shell of our ego. We've been pushed out of our comfort zones. In this process, I recommend the teams who continue to work online to CHECK-IN. For example, the teams periodically; Before regular meetings, checking-IN and making the emotional area visible before getting to the main topic increases the efficiency of the meeting. Or, starting the week, it creates an opportunity for the team to see where they stand in uncertainty and slow down to look at the big picture.",
        text6: "For example; If we were talking about resilience during the pandemic process, we could ask one of the following questions as a CHECK-IN question. (Questions can be varied depending on the context)",
        text7: "What questions do you have about our pandemic experience? What issues have become clear in your life during the quarantine period? What interesting thing did you notice about yourself about your quarantine process? What are my “good luck” in this process? What made you grow in this process? How do you support yourself? What do I need to bring my best self to work and be myself?"
    },
    {
        id: "TR",
        header: "Check-In Yapalım",
        text1: "Takımlarla workshop, takım koçluğu ya da eğitim için bir araya geldiğimizde ilk yaptığımız CHECK-IN yapmak olur.  İngilizce hali ile kullanıyorum çünkü zaten havayolu endüstrisindeki kullanım dolayısı ile Türkçeleştirmeden kullanıyoruz. Nedir CHECK-IN ve nasıl yapılır?",
        text2: "Kısaca herkesi bulunduğumuz alana, konuya getirmek, bulunduğu yerde bulunma niyetini fark ettirmek için güçlü bir soru sorarız ve herkes sırası ile bu sorunun kendilerinde canlandırdığı duygu, düşünce, istek ve/ya etkiyi paylaşır. Peki neden bunu yaparız ve ne işe yarar takımla bu şekilde çalışmaya başlamak? Check-in; “an’da” “şimdi ve burada” olmak için bir davet. Ekip üyelerinin özgün duygu ve düşüncelerini paylaşabileceği bir alan yaratır. Takımdaki tüm seslerin duyulmasını ve kendi sesinizin durduğu yeri görmenizi sağlar.  Kafamızdaki hali hazırda var olan düşünceleri ve kanıları kapının dışında bırakıp, gerçekte neler olduğunu yargısız görmemize yardımcı olur. Dile getirdiğiniz sizi rahatsız eden her neyse onu arkaya atmak kolaylaşır! Takım dinamiğinde içinde bulunduğunuz hal normalize olur, kendinizi yalnız hissetmezsiniz.",
        text3: "CHECK-IN aslında yeni bir başlangıç yaparken alanı temizler, beyaz bir sayfa açar. Açılan bu alan takım olarak niyet ettiğiniz yaratımınız için zemin oluşturur.",
        text4: "Her CHECK-in bir soru etrafında yapıldığı için kolektif algıyı, sistemde var olan seslerin duyulmasını dolayısı ile grubun kendisini görmesini sağlar, grup kendi kendine ayna olur. Grubun duygusal alanının anlık bir fotoğrafını verir.  Takım içinde var olan eğilimleri, ortaklıkları ve farklı perspektifleri ortaya çıkarır.",
        text5: "Neden önemsiyorum Check-in sürecini derseniz, Pandemi dolayısı ile hepimizin rutinleri bozuldu, hayatımızın önemli alanlarında başta sağlık olmak üzere EGO’muzun kabuğunu oluşturan kariyer, aile, arkadaşlık, fiziksel çevre gibi alanlarda zeminimiz değişti. Konfor alanlarımızın dışına itildik. Bu süreçte online çalışmaya devam eden ekiplere CHECK-IN yapmalarını öneriyorum. Takımların belli aralıklarla mesela; düzenli toplantılar öncesi asıl konuya girmeden önce ayrılacak bir sürede CHECK-IN yapıp duygusal alanı görünür kılmaları toplantı verimliliğini arttırır.  Veya haftaya başlarken takımın belirsizlik içinde nerede olduklarını görmeleri ve yavaşlayarak büyük resme bakmaları için fırsat yaratır.",
        text6: "Örneğin; Pandemi sürecinde esnek dayanıklılığı konuşuyor olsaydık aşağıdaki sorulardan birini CHECK-IN sorusu olarak sorabilidik. (Bağlama göre sorular çeşitlendirilebilir)",
        text7: "Yaşadığımız pandemi deneyimine dair kafanda hangi sorular var? Karantina sürecinde hayatında hangi konular netleşti? Karantina sürecinle ilgili kendinle ilgili ilginç neyi farkettin? Bu süreçte “iyiki”lerim neler? Bu süreçte seni ne büyüttü? Kendini nasıl destekliyorsun? En iyi halimi işe getirmek ve kendim olmak için neye ihtiyacım var?"
    }
];

class CheckIn extends Component {
    componentDidMount() {
        const text = `CheckIn: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="PageItemHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <p>{lItem.text1}</p>
                    <img
                        src={CheckInImg}
                        alt="Check-In Yapalım"
                        width="40%"
                        height="auto"
                        align="left"
                        hspace="20"
                        vspace="5"
                    />
                    <p>{lItem.text2}</p>
                    <p>{lItem.text3}</p>
                    <p>{lItem.text4}</p>
                    <p>{lItem.text5}</p>
                    <p>{lItem.text6}</p>
                    <p>{lItem.text7}</p> 
                    <p>                      
                    Vicdan Merter CPCC, PCC, ACTC
                    </p>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(CheckIn);