import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import PageItem from '../PageItem';

import Kocluk from './Kocluk';
import DigiKoc from './DigiKoc';
import Mentor from './Mentor';
import Takim from './Takim';
import Expat from './Expat';
import Egitim from './Egitim';
import IK from './IK';
import Ruanda from './Ruanda';

const lList = [
    {
        id: "EN",
        header: "Home",
        itemHeader1: "Coaching",
        itemText1: "Coaching is a unique relationship model rather than a methodology. This relationship depends on the client's agenda ...",
        //itemHeader2: "Digital Business Coaching",
        //itemText2: "Digital Business Coaching refers to the use of technology-based platforms to deliver business coaching services. This can ...",
        itemHeader3: "Mentor Coaching",
        itemText3: "Mentor coaching, which you must take on the way to become a professional coach with an ICF title, helps you to consolidate your ICF Core ...",
        itemHeader4: "Team Coaching",
        itemText4: "It is very powerful to achieve individual change, but to create a common culture of success in a group of people who make up the team ...",
        //itemHeader5: "Expat Coaching",
        //itemText5: "With Expat Coaching, you can manage your emotional reactions, learn about your new country and its culture, and adapt quickly to your new lifestyle ...",
        itemHeader6: "Training Services",
        itemText6: "Based on adult learning and active participation, coaching and facilitation skills and sharing participants' own experiences, perspectives and questions ...",
        itemHeader7: "HR Consulting",
        itemText7: "In today's world, since information and technology are easily accessible and copyable, human resources are the most important capital and ...",
        itemHeader8: "Rwanda",
        itemText8: "In March 2018 I spent 1 week supporting Humanium in the project for the realization of children's rights in Rwanda...",
        text1: "Back"
    },
    {
        id: "TR",
        header: "Ana Sayfa",
        itemHeader1: "Koçluk",
        itemText1: "Koçluk bir metodoloji olmaktan çok kendine özgü bir ilişki modelidir. Bu ilişki danışanın gündemine göre ...",
        //itemHeader2: "Dijital İş Koçluğu",
        //itemText2: "Dijital İş Koçluğu, koçluk hizmetleri sunmak için teknoloji tabanlı platformların kullanılmasını ifade eder. Bu, çevrimiçi ...",
        itemHeader3: "Mentor Koçluğu",
        itemText3: "ICF ünvanlı profesyonel koç olma yolunda almanız zorunlu olan mentor koçluğu, ICF Temel yetkinliklerinin pekişmesine, güçlü yönleriniz ve gelişim ...",
        itemHeader4: "Takım Koçluğu",
        itemText4: "Bireysel değişim elde etmek çok güçlü, fakat takımı oluşturan bir grup insanda ortak bir başarı kültürü oluşturmak ...",
        //itemHeader5: "Expat Koçluğu",
        //itemText5: "Expat Koçluğu ile duygusal tepkilerinizi yönetmek, yeni ülkeniz ve kültürü hakkında bilgi edinmek ve yeni yaşam tarzınıza hızlı bir şekilde adapte olmak ...",
        itemHeader6: "Eğitim Hizmetleri",
        itemText6: "Yetişkin öğrenmesine ve aktif katılıma dayalı, koçluk ve fasilitasyon becerileri ile katılımcıların kendi deneyim, bakış açıları ve sorularını paylaşarak ...",
        itemHeader7: "İK Danışmanlığı",
        itemText7: "Günümüz dünyasında bilginin ve teknolojinin kolay erişilebilir ve kopyalanabilir olması dolayısı ile insan kaynağı en önemli sermaye ve ...",
        itemHeader8: "Ruanda",
        itemText8: "Mart 2018'de Ruanda'da çocuk haklarının hayata geçirilmesi projesinde Humanium'u desteklemek için 1 hafta geçirdim ...",
        text1: "Geri Dön"
   }
];

class Home extends Component {
    state = {
        detayGoster: 'None'
    }

    componentDidMount() {
        const text = `Home: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    handleClick = () => {
        this.setState({ detayGoster: 'None' });
    }

    callPage = (detay) => {
        this.setState({ detayGoster: detay });
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (       
            <div>
                <div className="PageHeader">
                    {lItem.header}
                </div>
                {this.state.detayGoster === 'None' ?
                    <div className="Page">
                    <PageItem
                        Image="Kocluk"
                        Header={lItem.itemHeader1}
                        shortText={lItem.itemText1}
                        callPage={this.callPage} />
                    {/*}
                    <PageItem
                        Image="DigiKoc"
                        Header={lItem.itemHeader2}
                        shortText={lItem.itemText2}
                        callPage={this.callPage} />
                    */}
                    <PageItem
                        Image="Mentor"
                        Header={lItem.itemHeader3}
                        shortText={lItem.itemText3}
                        callPage={this.callPage} />
                    <PageItem
                        Image="Takim"
                        Header={lItem.itemHeader4}
                        shortText={lItem.itemText4}
                        callPage={this.callPage} />
                    {/*
                    <PageItem
                        Image="Expat"
                        Header={lItem.itemHeader5}
                        shortText={lItem.itemText5}
                        callPage={this.callPage} />
                    */}
                    <PageItem
                        Image="Egitim"
                        Header={lItem.itemHeader6}
                        shortText={lItem.itemText6}
                        callPage={this.callPage} />
                    <PageItem 
                        Image="IK"
                        Header={lItem.itemHeader7}
                        shortText={lItem.itemText7}
                        callPage={this.callPage} />
                    <PageItem
                        Image="Ruanda"
                        Header={lItem.itemHeader8}
                        shortText={lItem.itemText8}
                        callPage={this.callPage} />
                    </div>
                    :
                    <div>
                        {
                            this.state.detayGoster === 'Kocluk' ? <Kocluk language={this.props.language} /> :
                            //this.state.detayGoster === 'DigiKoc' ? <DigiKoc language={this.props.language} /> :
                            this.state.detayGoster === 'Mentor' ? <Mentor language={this.props.language} /> :
                            this.state.detayGoster === 'Takim' ? <Takim language={this.props.language} /> :
                            //this.state.detayGoster === 'Expat' ? <Expat language={this.props.language} /> :
                            this.state.detayGoster === 'Egitim' ? <Egitim language={this.props.language} /> :
                            this.state.detayGoster === 'IK' ? <IK language={this.props.language} /> :
                            this.state.detayGoster === 'Ruanda' ? <Ruanda language={this.props.language} /> :
                            <div>404</div>
                        }
                        <div className="CAButton" onClick={this.handleClick}>
                            {lItem.text1}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Home);
