import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import LiderlikImg from '../images/liderlik.png';

const lList = [
    {
        id: "EN",
        header: "Everyone is a Leader",
        text1: "Leadership is a very important issue for the business world and organizations. As there are multiple definitions of leadership, there are also different leadership styles. Although there are many definitions and measurement methods, leadership is still a concept that continues to evolve and transform. In this article, I would like to tell you about the '4-Dimensional Leadership Model' of CTI, one of the world's leading coaching schools.",
        text2: "We see that the 'hierarchical leadership' model, which is the accepted leadership model today, does not work very well. As an example, we can give the management structure of companies and the world of politics and economy. In such structures, leadership is assigned to a position, title, or function. And those who are given the responsibility of these roles, like a task, are expected to perform the leadership task.",
        text3: "What if leadership were not a function that only one person or position had to fulfill, but a skill and talent that anyone could show at any time?",
        text4: "Leadership is a choice, and it begins with a willingness to be responsible for one's own life and what's going on around it.",
        text5: "Multidimensional Leadership Model",
        text6: "CTI's leadership approach believes that everyone is a leader and believes that if everyone can access this leadership capacity they have, we will have more resources to overcome the challenges we face.",
        text7: "We can summarize CTI's leadership model as the transition from 'self'-centered leadership based on individual leadership capacity to'we'-oriented leadership.",
        text8: "As individuals with different abilities and unique characteristics, each of us can lead from the front, the back, the side or the whole by using our own special abilities in every situation we are in.",
        text9: "CTI's Leadership Model defines 5 main dimensions for effective leadership.",
        text10: "Front Leadership- “Leading in Front”",
        text11: "This is the dimension of leadership in the sense that we know and are used to. People who know where they want to go and set an effective and clear direction for those around them exhibit this type of leadership. We can give examples of names such as Nelson Mandela, Steve Jobs or Bill Gates to this leadership style in which the leader is prominent and visible. Therefore, as leaders, we take inspiration from these people and want to be like them. It can be very tiring to always lead from the front, and from time to time we question ourselves why I have to do everything. Let's get to know the other dimensions of leadership so that we have the opportunity to balance the responsibility of leadership on us!",
        text12: "Side Leadership-“Leading Beside”",
        text13: "It is a method of leading with shared responsibility by pairing with one another. Thanks to the solidarity of two people, it is possible to take more risks together and to lean on each other when necessary, in this type of leadership. Parenting can be a good example of this. Each of the parents demonstrates their contribution to different aspects and shows more effective leadership than two separate people. The co-chairmanship system can be given as another example of lateral leadership.",
        text14: "Leadership From Behind-“Leading from Behind”",
        text15: "Leading by empowering and supporting the person to unlock their maximum potential.",
        text16: "Coaching/mentoring is a good example. When you consciously and intentionally help someone succeed and shine, you lead from behind. The phrase “behind every successful man there is a self-sacrificing woman” in our language reflects the leadership style of women in our culture. Good managers and good leaders strive to develop their team and make them shine. In this way, employees who believe in themselves and feel supported by their managers maximize their potential and show superior performance. You will see that people who are given the opportunity, supported and allowed to make mistakes in the working environment develop more. Managers with this type of leadership are needed to develop talent.",
        text17: "“Leading from Within”",
        text18: "As you navigate through all these leadership dimensions, your heart, emotions, instincts, and sixth sense are with you. Being aware of these concepts that are under your own personality iceberg and choosing to lead from here is self-leadership. It is possible to make conscious leadership choices by accessing these qualities without relying on the mind alone. Leadership from the essence requires being one with what he says and the courage to listen to your inner voice and put it forward. When faced with a situation where you need to show leadership, stop and try to hear what you are feeling and what your body and your inner voice are telling you. Don't judge right or wrong based on your mind alone, you have more as a human! Show the qualities that make you you while leading, be yourself.",
        text19: "Whole Leadership-“Leading from Whole”",
        text20: "When things don't go your way, looking from the overall leadership dimension may be the best solution to change the direction of the wind. This dimension, which we will define as a holistic/total view, looking down or trying to understand the big picture, focuses on understanding the need from what is happening at the moment and creating solutions from there. In such a case, the first step is; stop; The second step is to ask yourself what is going on here, what is important to me, and in the third step, to understand what is/will be and what the situation will turn out to be, by trying to hear their point of view as “one” with your team.",
        text21: "This leadership style requires transforming the ego-based operating system that focuses on individual interests into an awareness that cares about the interests of the whole, as we see in the ecosystem in nature.",
        text22: "“I” am important as a part of the whole and I contribute to shaping the future. “I” cares about others/the whole as much as I care about my own struggle for life and I choose accordingly. What is good for me and for everyone?",
        text23: "Multidimensional leadership requires knowing and consciously choosing to use these dimensions. If you want to be aware of the big picture and show leadership that empowers you and your team, try it!"
    },
    {
        id: "TR",
        header: "Herkes Liderdir",
        text1: "İş dünyası ve organizasyonlar için liderlik çok önemli bir konu. Liderliğin birden çok tanımı olduğu gibi farklı sayıda liderlik stilleri de mevcut. Çok fazla sayıda tanımı ve ölçme yöntemi olmasına rağmen hala gelişmeye ve dönüşmeye devam eden bir kavram liderlik. Ben bu yazımda size dünyanın önde gelen Koçluk okullarından CTI’ın “4 Boyutlu Liderlik Modeli”nden bahsetmek istiyorum.",
        text2: "Bugün kabul edilmiş liderlik modeli olan “hiyerarşik liderlik” modelinin pek işe yaramadığını görüyoruz. Buna örnek olarak şirketlerin yönetim yapısı ile politika ve ekonomi dünyasını verebiliriz. Bu tür yapılarda liderlik bir pozisyona, titre ya da fonksiyona atanmıştır. Ve bir görev gibi bu rollerin sorumluluğu verilmiş olan kişilerin liderlik vazifesini ifa etmesi beklenir.",
        text3: "Peki ya liderlik sadece ve sadece bir kişi ya da pozisyonun yerine getirmesi gereken bir fonksiyon olmasa da her an herkesin gösterebileceği bir beceri ve yetenek olsa?",
        text4: "Liderlik bir seçimdir ve kendi hayatından ve etrafında olan bitenden sorumlu olma isteği ile başlar.",
        text5: "Çok Boyutlu Liderlik Modeli",
        text6: "CTI’nın liderlik yaklaşımı herkesin lider olduğuna inanıyor ve herkesin sahip olduğu bu liderlik kapasitesine erişebilirse karşılaştığımız zorlukları aşmak için daha fazla kaynağımız olur düşüncesinde.",
        text7: "CTI’nın liderlik modelini bireysel liderlik kapasitesine dayanan “ben” merkezli liderlikten “biz” odaklı liderliğe geçiş olarak özetleyebiliriz.",
        text8: "Her birimiz farklı yetenek ve kendimize özgü özellikleri olan bireyler olarak içerisinde bulunduğumuz her durumda kendi özel yeteneklerimizi kullanarak önden, arkadan, yandan ya da bütünden liderlik yapabiliriz.",
        text9: "CTI’nın Liderlik Modelinde etkili liderlik için 5 ana boyut tanımlanıyor.",
        text10: "Öz Liderlik- “Leading in Front”",
        text11: "Bu bizim bildiğimiz ve alışık olduğumuz anlamda liderlik boyutu. Nereye gitmek istediğini bilen ve bu uğurda etrafındakilere etkili ve net bir yön belirleyen kişiler bu tarz liderlik sergilemiş olurlar. Liderin önde ve görünür olduğu bu liderlik tarzına Nelson Mandela, Steve Jobs ya da Bill Gates gibi isimleri örnek verebiliriz. Dolayısıyla lider olarak bu kişilerden ilham alır ve onlar gibi olmak isteriz. Her zaman önden liderlik etmek çok yorucu olabilir, zaman zaman neden her işi ben yapmak zorundayım diye kendimizi sorgularız. Gelin diğer liderlik Boyutlarını da tanıyalım ki üzerimizdeki liderlik sorumluluğunu dengeleme fırsatımız olsun!",
        text12: "Yandan Liderlik-“Leading Beside”",
        text13: "Bu biri ile eşleşerek ortak sorumlulukla birlikte liderlik etme yöntemidir. İki kişinin dayanışması sayesinde birlikte daha fazla risk almak ve gerektiğinde birbirine yaslanmak mümkün bu tarz liderlikte. Ebeveynlik buna iyi bir örnek olabilir. Ebeveynlerden her biri farklı yönlerini katkılarını ortaya koyarak iki ayrı kişiden daha etkili bir liderlik gösterirler. Eş başkanlık sistemi yandan liderliğe başka bir örnek olarak verilebilir.",
        text14: "Arkadan Liderlik-“Leading from Behind”",
        text15: "Kişiyi güçlendirerek maksimum potansiyelini ortaya çıkaracak şekilde destekleyerek liderlik etme.",
        text16: "Koçluk /mentorluk buna iyi bir örnek sayılır. Bilinçli ve niyetli olarak birinin başarılı olması ve parlaması için yardım ettiğinizde arkadan liderlik etmiş olursunuz. Dilimizde “her başarılı erkeğin arkasında fedakar bir kadın vardır” deyimi bizim kültürümüzde kadının gösterdiği liderlik tarzını yansıtır. İyi yöneticiler ve iyi liderler ekibini geliştirmek ve onların parlaması için çaba sarf eder. Bu sayede yöneticisi kendisine inanan ve yöneticisi tarafından desteklendiğini hisseden çalışanlar potansiyellerini en yükseğe çıkartıp üstün performans sergilerler. Çalışma ortamında da kendisine fırsat verilen, desteklenen, hata yapmasına izin verilen kişilerin daha çok geliştiğini görürsünüz. Yeteneğin geliştirilmesi için bu tarz liderlik sergileyen yöneticilere ihtiyaç duyulur.",
        text17: "Özden Liderlik- “Leading from Within”",
        text18: "Tüm bu liderlik boyutları arasında gezinirken kalbiniz, duygularınız, içgüdüleriniz, altıncı hissiniz sizinle beraber. Kendi özünüze ait olan kişilik buzdağınızın altında olan bu kavramların farkında olarak buradan liderlik etmeyi seçmek özden liderlik. Sadece zihne dayanmadan bu özelliklerinize de erişerek şuurlu liderlik tercihleri yapmak mümkün. “Özü sözü bir” yani söylediği ile yaptığı bir olmayı, iç sesinizi dinleyip onu ortaya koyma cesareti gerektirir özden liderlik. Liderlik göstermeniz gereken bir durumla karşılaştığınızda durun ve ne hissettiğinizi ve bedeninizin, iç sesinizin size ne söylediğini duymaya çalışın. Doğru ya da yanlış değerlendirmesini yalnızca zihninize dayanarak yapmayın, insan olarak daha fazlasına sahipsiniz! Sizi siz yapan özelliklerinizi liderlik yaparken de sergileyin, kendiniz olun.",
        text19: "Tümden Liderlik-“Leading from Whole”",
        text20: "İşler yolunda gitmediğinde tümden liderlik boyutundan bakma, rüzgarın yönünü değiştirmek için en iyi çözüm olabilir. Bütünsel/toplam bakış, tepeden bakma ya da büyük resmi anlamaya çalışma olarak tanımlayacağımızbu boyut o anda olan bitenden ihtiyacı anlamaya ve oradan çözüm yaratmaya odaklanır. Böyle bir durumda, birinci adım; durmak; ikinci adım kendinize burada ne oluyor, benim için ne önemli diye sormak ve üçüncü adımda ise takımınızdakilerle “bir” olarak onların bakış açılarını duymaya çalışarak hep birlikte, ne olduğunu/olacağını durumun neye dönüşeceğini anlamak.",
        text21: "Bu liderlik tarzı bireysel çıkarlara odaklanan egoya dayalı işletim sistemini doğadaki ekosistemde gördüğümüz gibi bütünün çıkarlarını önemseyen bir farkındalığa dönüştürmeyi gerektirir.",
        text22: "“Ben” bütünün bir parçası olarak önemliyim ve geleceği şekillendirmeye katkım var. “Ben” kendi yaşam mücadelemi önemsediğim kadar diğerlerini/bütünü de önemsiyor ve ona göre seçim yapıyorum. Benim için ve herkes için iyi olan ne?",
        text23: "Çok boyutlu liderlik bu boyutların farkında olarak ve bilinçli bir şekilde bu boyutları kullanmayı seçmeyi gerektirir. Büyük resmin farkında olmayı ve size ve takımınızı güçlendiren bir liderlik sergilemek istiyorsanız deneyin!"
    }
];

class Liderlik extends Component {
    componentDidMount() {
        const text = `Liderlik: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="PageItemHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <p>{lItem.text1}</p>
                    <img
                        src={LiderlikImg}
                        alt={lItem.header}
                        width="40%"
                        height="auto"
                        align="left"
                        hspace="20"
                        vspace="5"
                    />
                    <p>{lItem.text2}</p>
                    <p>{lItem.text3}</p>
                    <p>{lItem.text4}</p>
                    <p>{lItem.text5}</p>
                    <p>{lItem.text6}</p>
                    <p>{lItem.text7}</p>
                    <p>{lItem.text8}</p>
                    <p>{lItem.text9}</p>
                    <p>{lItem.text10}</p>
                    <p>{lItem.text11}</p>
                    <p>{lItem.text12}</p>
                    <p>{lItem.text13}</p>
                    <p>{lItem.text14}</p>
                    <p>{lItem.text15}</p>
                    <p>{lItem.text16}</p>
                    <p>{lItem.text17}</p>
                    <p>{lItem.text18}</p>
                    <p>{lItem.text19}</p>
                    <p>{lItem.text20}</p>
                    <p>{lItem.text21}</p>
                    <p>{lItem.text22}</p>
                    <p>{lItem.text23}</p>
                    <p>Vicdan Merter CPCC, PCC, ACTC</p>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Liderlik);