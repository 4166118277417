import React, { Component } from 'react';

import Jungian from './images/jungian-logo.png';
import Sparrks from './images/Sparrks_Logo.png';
import KornFerry from './images/KFY_BIG.png';
import SparkUs from './images/sparkus_logo.png';
import BetterUp from './images/BetterUp_Logo.png';
import Speexx from './images/speex_logo.png';
import GrowthSpace from './images/growthspace-logo.png';

const lList = [
    {
        id: "EN",
        text1: "Some of the international references that I provide my services:"
    },
    {
        id: "TR",
        text1: "Hizmet verdiğim uluslararası referanslardan bazıları:"
    }
];

class Links extends Component {
    handleClick = (pg) => {
        let url = '';
        switch(pg) {
            case 'Jungian':
                url = 'https://www.jungiancoachingschool.com/';
                break;
            case 'Sparrks':
                url = 'https://www.sparrks.io/';
                break;
            case 'KornFerry':
                url = 'https://www.kornferry.com/';
                break;
            case 'SparkUs':
                url = 'https://www.sparkus.com/';
                break;
            case 'BetterUp':
                url = 'https://www.betterup.com/';
                break;
            case 'Speexx':
                url = 'https://www.speexx.com/';
                break;
            case 'GrowthSpace':
                url = 'https://www.growthspace.com/';
                break;
            default:
                return (0);
        }
        const win = window.open(url, '_blank');
        if (win != null) {
              win.focus();
        }
        return (1);
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });
    //  <img className='Link' src={KornFerry} height="40px" alt='KornFerry' onClick={() => this.handleClick('KornFerry')}/>

        return (
            <div className="Links">
                <div className='LinkHeader'>
                    {lItem.text1}
                </div>
                <img className='Link' src={Jungian} height="40px" alt='Jungian' onClick={() => this.handleClick('Jungian')}/>
                <img className='Link' src={BetterUp} height="40px" alt='BetterUp' onClick={() => this.handleClick('BetterUp')}/>
                <img className='Link' src={SparkUs} height="40px" alt='SparkUs' onClick={() => this.handleClick('SparkUs')}/>
                <img className='Link' src={Sparrks} height="40px" alt='Sparrks' onClick={() => this.handleClick('Sparrks')}/>
                <img className='Link' src={KornFerry} height="40px" alt='KornFerry' onClick={() => this.handleClick('KornFerry')}/>
                <img className='Link' src={Speexx} height="40px" alt='Speexx' onClick={() => this.handleClick('Speexx')}/>
                <img className='Link' src={GrowthSpace} height="40px" alt='GrowthSpace' onClick={() => this.handleClick('GrowthSpace')}/>
            </div>
        )
    }
}

export default Links;