import React, { Component } from 'react';

import Kocluk from './images/stones.jpg';
import DigiKoc from './images/leavesrainbow.jpg';
import Takim from './images/leaves.jpg';
import Mentor from './images/wateringplant.jpg';
import Expat from './images/globe.jpg';
import Egitim from './images/plant.jpg';
import IK from './images/dragonfly.jpg';
import Ruanda from './images/Ruanda2.jpg';
import CheckIn from './images/checkin.png';
import Liderlik from './images/liderlik.png';
import Farkindalik from './images/farkinda.png';
import Mahser from './images/mahser.jpeg';
import DortAnlasma from './images/dort.png';
import Kurban from './images/kurban.png';
import Mola from './images/mola.jpg';

class PageItem extends Component {
    state = {
        defaultBorderColor: 'BorderDefaultColor',
        showLongText: false
    }

    handleClick = () => {
        if (this.props.callPage)
            this.props.callPage(this.props.Image);
    }

    handleMouseEnter = () => {
        this.setState({ defaultBorderColor: 'BorderHighlightColor' });
    }

    handleMouseLeave = () => {
        this.setState({ defaultBorderColor: 'BorderDefaultColor' });
    }

    render() {
        const iconObj = {
            'Kocluk': Kocluk,
            'DigiKoc': DigiKoc,
            'Takim': Takim,
            'Mentor': Mentor,
            'Expat': Expat,
            'Egitim': Egitim,
            'IK': IK,
            'Ruanda': Ruanda,
            'CheckIn': CheckIn,
            'Liderlik': Liderlik,
            'Mahser': Mahser,
            'Farkindalik': Farkindalik,
            'DortAnlasma': DortAnlasma,
            'Kurban': Kurban,
            'Mola': Mola
        }

        return (
            <div className="Page">
            <div
                className={"PageItem " + this.state.defaultBorderColor}
                onClick={this.handleClick}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            > 
                <div className="PageItemHeader">
                        {this.props.Header}
                </div>
                <div className="PageItemImage">
                    <img
                        src={iconObj[this.props.Image]}
                        alt={this.props.Image}
                        height="100%"
                        width="auto"
                    />
                </div>
                <div className="PageItemText">
                    {this.props.shortText}
                </div>
            </div>
            </div>
        )
    }
}

export default PageItem;
  