import React, { Component } from 'react';

import cakare from './images/ca-kare.png';
import facebook from './images/sm_facebook.png';
import instagram from './images/sm_instagram.png';
import linkedin from './images/sm_linkedin.png';
import twitter from './images/sm_twitter.png';
import whatsapp from './images/sm_whatsapp.png';

import actcBadge from './images/Badge_ACTC.png';
import cpccBadge from './images/Badge_CPCC.png';
import pccBadge from './images/Badge_PCC.png';
import clBadge from './images/Badge_CoactiveLeadership.png';

import cpccPdf from './pdfs/cer_cpcc.jpeg';
import pccPdf from './pdfs/cer_pcc.pdf';
import actcPdf from './pdfs/cer_actc.pdf';

const lList = [
    {
        id: "EN",
        text1: "Photo credits to freepik.com"
    },
    {
        id: "TR",
        text1: "Resimler freepik.com'dan alıntıdır"
    }
];

class Footer extends Component {
    handleClick = (pg) => {
        let url = '';
        switch(pg) {
            case 'facebook':
                url = 'https://www.facebook.com/coachathlon/';
                break;
            case 'instagram':
                url = 'https://www.instagram.com/coachathlon/';
                break;
            case 'linkedin':
                url = 'https://www.linkedin.com/company/coachathlon/';
                break;
            case 'twitter':
                url = 'https://twitter.com/coachathlon/';
                break;
            case 'whatsapp':
                url = 'https://api.whatsapp.com/send?phone=+905322625684&text=Bilgi ltf';
                break;
            case 'freepik':
                url = 'https://www.freepik.com/photos/nature';
                break;
            case 'cpcc':
                url = cpccPdf;
                break;
            case 'pcc':
                url = pccPdf;
                break;
            case 'actc':
                url = actcPdf;
                break;
            case 'cl':
                //url = clPdf;
                break;
            default:
                return (0);
        }
        const win = window.open(url, '_blank');
        if (win != null) {
              win.focus();
        }
        return (1);
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });
        
        return (
            <div className="Footer">
                <div className="FooterLogo">
                    <img src={cakare} alt="Coachathlon"/>
                </div>
                <div className="FooterCenter">
                    <img className="FooterLink" src={cpccBadge} height="60px" alt='CPCC' onClick={() => this.handleClick('cpcc')}/>
                    <img className="FooterLink" src={pccBadge} height="60px" alt='PCC' onClick={() => this.handleClick('pcc')}/>
                    <img className="FooterLink" src={actcBadge} height="60px" alt='ACTC' onClick={() => this.handleClick('actc')}/>
                    <img className="FooterLink" src={clBadge} height="60px" alt='CLP' onClick={() => this.handleClick('cl')}/>
                </div>
                <div className="FooterRight">
                    <div className="FooterLinks">
                        <img className="FooterLink" src={facebook} alt="Facebook" height='50' onClick={() => this.handleClick('facebook')} />
                        <img className="FooterLink" src={instagram} alt="Instagram" height='50' onClick={() => this.handleClick('instagram')} />
                        <img className="FooterLink" src={linkedin} alt="LinkeIn" height='50' onClick={() => this.handleClick('linkedin')} />
                        <img className="FooterLink" src={twitter} alt="Twitter" height='50' onClick={() => this.handleClick('twitter')} />
                        <img className="FooterLink" src={whatsapp} alt="email" height='50' onClick={() => this.handleClick('whatsapp')} />
                    </div>
                    <div className="FooterCredits"
                        onClick={() => this.handleClick('freepik')}>
                        {lItem.text1}
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;