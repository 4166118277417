import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import ExpatImg from '../images/globe.jpg';

const lList = [
    {
        id: "EN",
        header: "Expat Coaching",
        text1: "Moving to another country with a new mission can be both exciting and challenging. Moving to another country is actually a big change. Not only our careers, but also our home, friendships, culture, and support environment change. This change can trigger unexpected emotional responses. If the reactions to this change are not managed when you step out of your comfort zone and face a new and unknown situation, it can be even more difficult to organize your new life and focus on your work.",
        text2: "With Expat Coaching, it is possible to develop the skills you need to manage your emotional reactions, learn about your new country and culture, and adapt quickly to your new lifestyle, with your expat coach working one-on-one!",
        text3: "This highly customized coaching experience can be done face-to-face or remotely with the help of videoconferencing tools."
    },
    {
        id: "TR",
        header: "Expat Koçluğu",
        text1: "Yeni bir görevle başka bir ülkeye geçmek hem heyecan verici hem de çok zor olabilir. Başka bir ülkeye taşınmak aslında büyük bir değişimdir. Sadece kariyerimiz değil evimiz, dostluklarımız, kültür, destek aldığımız çevre de değişir. Bu değişim beklenmedik duygusal tepkileri tetikleyebilir. Konfor alanından çıkıp yeni ve bilinmez durumla karşılaşıldığında bu değişime verilecek reaksiyonlar yönetilmezse, yeni hayatınızı düzenlemek ve işinize odaklanmak daha da zor olabilir.",
        text2: "Expat Koçluğu ile duygusal tepkilerinizi yönetmek, yeni ülkeniz ve kültürü hakkında bilgi edinmek ve yeni yaşam tarzınıza hızlı bir şekilde adapte olmak için ihtiyaç duyduğunuz becerileri geliştirmek expat koçunuz bire bir çalışmakla mümkün!",
        text3: "Bu son derece özelleştirilmiş koçluk deneyimi, yüzyüze ya da video konferans programları yardımı ile uzaktan yapılabilmektedir."
    }
];

class Expat extends Component {
    componentDidMount() {
        const text = `Expat: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="PageItemHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <p>{lItem.text1}</p>
                    <img
                        src={ExpatImg}
                        alt="Expat Koçluğu"
                        width="40%"
                        height="auto"
                        align="left"
                        hspace="20"
                        vspace="5"
                    />
                    <p>{lItem.text2}</p>
                    <p>{lItem.text3}</p>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Expat);