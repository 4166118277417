import React from 'react';

import Img1 from './images/iskele.jpg';

function MainImage() {
    return (
        <div className="MainImage">
            <img src={Img1} alt="Team Coaching" width="100%"/>
        </div>
    )
}

export default MainImage;
