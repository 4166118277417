import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import DigiKocImg from '../images/leavesrainbow.jpg';

const lList = [
    {
        id: "EN",
        header: "Digital Business Coaching",
        text1: "Digital Business Coaching refers to the use of technology-based platforms to deliver coaching services. This can include online video conferencing platforms, messaging apps, email, and other digital tools that enable communication and collaboration between the coach and the client. ",
        text2: "Coaching on digital platforms offers several benefits. It allows clients to access coaching services from anywhere in the world, making it more convenient and accessible for people with busy schedules or limited mobility. Additionally, it can be more cost-effective than in-person coaching since it eliminates the need for travel and associated expenses.",
        text3: "Overall, coaching on digital platforms is a convenient and effective way to deliver coaching services and can be particularly beneficial for individuals or organizations seeking to improve their skills and competencies."
    },
    {
        id: "TR",
        header: "Dijital İş Koçluğu",
        text1: "Dijital İş Koçluğu, koçluk hizmetleri sunmak için teknoloji tabanlı platformların kullanılmasını ifade eder. Bu, çevrimiçi video konferans platformlarını, mesajlaşma uygulamalarını, e-postayı ve koç ile danışan arasında iletişimi ve işbirliğini sağlayan diğer dijital araçları içerebilir. ",
        text2: "Dijital platformlarda koçluk yapmak çeşitli avantajlar sunar. Müşterilerin dünyanın herhangi bir yerinden koçluk hizmetlerine erişmesine olanak tanıyarak yoğun programları veya sınırlı hareket kabiliyeti olan kişiler için daha kolay ve erişilebilir hale getirir. Ek olarak, seyahat ihtiyacını ve ilgili masrafları ortadan kaldırdığı için yüz yüze koçluktan daha uygun maliyetli olabilir.",
        text3: "Genel olarak, dijital platformlarda koçluk, koçluk hizmetleri sunmanın uygun ve etkili bir yoludur ve becerilerini ve yetkinliklerini geliştirmek isteyen bireyler veya kuruluşlar için özellikle faydalı olabilir."
    }
];

class Kocluk extends Component {
    componentDidMount() {
        const text = `DigiKoc: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="PageItemHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <img
                        src={DigiKocImg}
                        alt={lItem.header}
                        width="40%"
                        height="auto"
                        align="left"
                        hspace="20"
                        vspace="5"
                    />
                    <p>{lItem.text1}</p>
                    <p>{lItem.text2}</p>
                    <p>{lItem.text3}</p>
                    <p>{lItem.text4}</p>
                    <p>{lItem.text5}</p>
                    <p>{lItem.text6}</p>
                    <p>{lItem.text7}</p>
                    <p>{lItem.text8}</p>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Kocluk);