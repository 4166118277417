import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import KurbanImg from '../images/kurban.png';

const lList = [
    {
        id: "EN",
        header: "It is not my fault",
        text1: "There is a concept that I hear a lot these days. “Victim Behavior”… Let's try to understand what it is all together.",
        text2: "Victim Psychology means seeing every bad thing that happens to us as someone else's fault. What's more, it is believing that something bad will always happen to you: “Bad things always happen to us”. The victim blames others in every situation he encounters and does not take any responsibility for the situation that happens to him. As you can appreciate, this is a very wearisome point of view. This situation not only makes him feel pessimistic about what happened to him, but also makes him feel powerless. The person feels so powerless that he cannot take any steps to change the situation that negatively affects him.",
        text3: "Everything that happened to the victim, as it seemed to him, did not deserve it, it happened because of others, without any fault. And he always has a story to tell about the undesirable situation! The victim does not take any responsibility for the undesirable situation with the belief that 'Bad things always happen to me'.",
        text4: "As you can appreciate, this is a rather abrasive point of view. This situation not only exhibits a pessimistic attitude, but also causes the person to feel powerless. The person feels so powerless that he cannot take any steps to change the situation that negatively affects him.",
        text5: "According to psychology, this behavior stems from childhood experiences. In this period, from birth to early childhood, when children cannot meet their own needs, if the basic needs of children are not adequately met, the person adopts to stand weakly in the face of events as a chosen behavior.",
        text6: "The victim point of view causes the person to feel helpless and unable to take care of themselves or even become dependent on others when faced with difficulties in adult life. This is also not desirable for an adult. Victims become dependent on others to feel completely good (to be trusted, loved, and belong somewhere). As a result, they wander around in constant worry and low morale in case the person they are attached to leaves.",
        text7: "This attitude comes from within; not from the outside. The basic belief of the victim is feeling weak and worthless and “I am not enough”. This belief manifests to the victim as a fear of failure. Negative thoughts, similarly, create negative emotions and eventually turn into self-shame, over-criticism and judgment. The victim tries to get rid of it through reflection by blaming the environment and not taking responsibility.",
        text8: "Victims see what they expect from life as their right. When life doesn't give them what they want, they feel anger and frustration. This point of view creates a great problem for the person himself in all areas of life.",
        text9: "Everything starts with taking responsibility!",
        text10: "Well, what should we do to overcome this? First of all, we can start to take responsibility for everything that we encounter in life and everything that happens to us. Even if we cannot control what happens to us when we are willing and ready to take responsibility for our own lives, we can control our choice in the face of this situation. Victor Frankl, who discovered that it is an endless freedom to choose how we will react in the face of a difficult situation, managed to survive in the Nazi Camp in Auschwitz.",
        text11: "According to Frankl, there is a space between 'stimulus' and 'reaction'. Here you can choose how you will react to events and what your attitude will be in this area. What develops and liberates us is our power to choose our attitude towards events. Covey mentions this issue as the first habit in his book “The Seven Habits of Highly Effective People”: “Be proactive!” Proactive people focus on the things they can control within their sphere of influence, and in this way they can magnify their influence. Victims, on the other hand, focus on matters outside their sphere of influence. This deactivates them even more.",
        text12: "Imagine that there are three kinds of business in the world… Your own business, someone else's business, and God's business. You cannot interfere in God's business, but you have to suffer the consequences. For example, earthquakes, floods, storms are the work of God and affect us, but it is difficult for us to change. If it is the work of others, it is only in their sphere of influence. No matter how much you want it, you cannot get them to do anything without their consent. Nothing changes. Only your own business is in your sphere of influence. You can change it if you want.",
        text13: "To get rid of the victim's point of view.",
        text14: "The first step is to change your perspective. That is, turning from a victim to a “hero”… Aren't we all the protagonists of our own lives? If we're not going to play the leading role in the movie of our own life, what role can we play?",
        text15: "Heroes are legendary and they overcome challenges. They are not afraid of failure, they do their best, they take responsibility for their own lives and implement the decisions they take in a disciplined way. They do not choose the easy path and struggle when necessary. Its core values are responsibility, discipline, courage, honesty, trustworthiness, honor and self-respect.",
        text16: "• Become aware of your victim point of view and stop blaming.",
        text17: "• Embrace your power by taking responsibility for meeting your needs.",
        text18: "• Love and accept yourself as you are. Do not criticize and judge yourself.",
        text19: "• Confidence in your abilities, encourage yourself by saying I can and I can do it.",
        text20: "• Be honest with yourself, have a life principle where you respect everyone and care for everyone's well-being.",
        text21: "• Visualize the self you want to be.",
        text22: "• Forgive yourself when you fail or fail to meet your expectations."
    },
    {
        id: "TR",
        header: "Benim Hatam Değil",
        text1: "Bu aralar çok sık duyduğum bir kavram var. “Kurban Davranışı”….Gelin hep birlikte ne olduğunu anlamaya çalışalım.",
        text2: "Kurban Psikolojisi, başımıza gelen her kötü şeyi başkasının suçu olarak görmek demektir. Daha ötesi başına her zaman kötü birşeyin geleceğine inanmaktır: “Kötü şeyler hep bizim başımıza gelir”. Kurban, karşılaştığı her durumda başkasını suçlar ve basina gelen durumla ilgili kendisi hiç bir sorumluluk almaz.Takdir edersiniz ki, bu oldukça yıpratıcı bir bakış açısıdır.Bu durum sadece başına gelenle ilgili pesimist bir tutum sergilemenin yanında kendisini güçsüz hissetmesine de sebep olur. Kişi kendini o kadar güçsüz hisseder ki, kendisini olumsuz etkileyen durumu değiştirmek için hiç bir adım atamaz.",
        text3: "Kurbanın başına gelen her şey ona görünen hali ile hak etmediği, hiçbir suçu yokken başkalarının yüzünden gelmiştir. Ve istenmeyen durumla ilgili hep anlatacak bir hikayesi olur! Kurban “Kötü şeyler hep benim başıma gelir” inancı ile istenmeyen durumla ilgili hiçbir sorumluluk almaz.",
        text4: "Takdir edersiniz ki, bu oldukça yıpratıcı bir bakış açısıdır. Bu durum sadece pesimist bir tutum sergilemenin yanında kişinin kendisini güçsüz hissetmesine de sebep olur. Kişi kendini o kadar güçsüz hisseder ki, kendisini olumsuz etkileyen durumu değiştirmek için hiçbir adım atamaz.",
        text5: "Psikoljiye göre bu davranış çocukluk deneyimlerinden kaynaklanır. Doğumdan erken çocukluğa kadar çocukların kendi ihtiyaçlarını karşılayamadıkları bu dönemde eğer çocukların temel ihtiyaçları yeterince karşılanmazsa kişi olaylar karşısında zayıf durmayı seçilmiş bir davranış biçimi olarak edinir.",
        text6: "Kurban bakış açısı, kişinin yetişkin hayatında sıkışıklıkla karşılaştığında çaresiz hissetme ve kendi kendine bakamama hatta başkalarına bağımlı hale gelmesine sebep olur..Bu da bir yetişkin için istenen bir durum değil. Kurbanlar kendilerini tamamen iyi hissetmek (güvenmek, sevilmek ve bir yere ait olmak) için başkalarına bağımlı hale gelirler. Buna bağlı olarak bağlandıkları kişi, ya terk ederse diye sürekli endişe içerisinde ve düşük moralle dolaşırlar.",
        text7: "Bu tutum içerden gelir; dışarıdan değil. Kurbanın temel inanışı kendini zayıf ve değersiz görme ve “Ben yeterli değilim”dir. Bu inanış kurbana başarısızlık korkusu olarak tezahür eder. Olumsuz düşünceler, benzer şekilde olumsuz duygular yaratır ve sonuçta farkında olmadan kendi kendinden utanç duyarak, aşırı eleştirip yargılamaya dönüşür. Kurban etrafını suçlayarak ve sorumluluk almayarak bundan yansıtma yolu ile kurtulmaya çalışır.",
        text8: "Kurbanlar hayattan beklediklerini kendi hakkı olarak görür. Hayat onlara istediklerini vermediği zaman, kızgınlık ve hayal kırıklığı duyarlar. Bu bakış açısı yaşamın her alanında kişinin kendisi için büyük bir sorun oluşturur.",
        text9: "Herşey sorumluluk almakla başlar!",
        text10: "Peki, bunun üstesinden gelmek için ne yapmak lazım?Öncelikle hayatta karşılaştığımız ve başımıza gelen herşeyle ilgili sorumluluk almaya başlayabiliriz. Kendi hayatımızla ilgili sorumluluk almaya niyet edip buna hazır olduğumuz zaman başımıza gelenleri kontrol edemesek bile, bu durum karşısında seçimimizi kontrol edebiliriz. Karşılaştığımız zor bir durum karşısında nasıl bir tutum sergileyeceğimizi seçmemizin sonsuz bir özgürlük olduğunu keşfeden Victor Frankl bu sayede Auschwitz’teki Nazi Kampı’nda hayatta kalmayı başarmıştır.",
        text11: "Frankl’a göre “uyaran” ile “tepki” arasında bir alan var. İşte bu alanda olaylara nasıl tepki vereceğinizi, tutumunuzun ne olacağını seçebilirsiniz. Bizi geliştiren ve özgürleştiren de olaylar karşısındaki tutumumuzu seçme gücümüz. Bu konuya Covey, “Etkili İnsanların Yedi Alışkanlığı” kitabında birinci alışkanlık olarak yer veriyor: “Proaktif ol!” Proaktif insanlar kendi etki alanları içerisindeki kontrol edebildikleri işlere odaklanırlar ve bu şekilde etkilerini büyütebilirler. Kurbanlarsa kendi etki alanı dışındaki konulara odaklanırlar. Bu onları daha da etkisizleştirir.",
        text12: "Dünyada üç türlü iş olduğunu düşünün… Kendi işiniz, başkalarının işi ve Tanrının işi.. Tanrı’nın işine karışamazsınız ama sonuçlarına katlanmanız gerekir. Örneğin depremler, seller, fırtınalar Tanrı’nın işidir ve bizleri etkiler ama değiştirmemiz güçtür. Başkalarının işiyse, sadece onların etki alanındadır.Siz ne kadar isteseniz de onlar istemeden hiçbir şey yaptıramazsınız. Hiçbir şey değişmez. Sadece kendi işiniz sizin etki alanınızdadır. Siz isterseniz değiştirebilirsiniz.",
        text13: "Kurban bakış açısından kurtulmak için..",
        text14: "Bunun ilk adımı bakış açısını değiştirmektir. Yani kurbandan “Kahraman”a dönüşmek… Hepimiz kendi hayatımızın baş kahramanı değil miyiz? Kendi hayatımızın filminde başrol oynamayacaksak hangi rolü oynayabiliriz?",
        text15: "Kahramanlar efsanevidir ve zorlukların üstesinden gelirler. Başarısızlıktan korkmazlar, ellerinden gelenin en iyisini yaparlar, kendi hayatları ile ilgili sorumluluk alıp disiplinli şekilde aldıkları kararları uygularlar. Kolay yolu seçmezler ve gerektiğinde mücadele ederler. Temel değerleri, sorumluluk, disiplin, cesur, dürüst, güvenilir, onur ve özsaygıdır.",
        text16: "• Kurban bakış açınızın farkına varın ve suçlamayı bırakın.",
        text17: "• İhtiyaçlarınızı karşılamak için sorumluluk alarak gücünüze sahip çıkın.",
        text18: "• Kendinizi olduğunuz halinizle sevin ve kabul edin. Kendi kendinizi eleştirip yargılamayın.",
        text19: "• Yeteneklerinize güvenin, yapabilirim ve başarabilirim diyerek kendinizi cesaretlendirin.",
        text20: "• Kendinize karşı dürüst olun, herkese saygı duyduğunuz ve herkesin iyiliğini düşündüğünüz bir yaşam prensibiniz olsun.",
        text21: "• Olmak istediğiniz halinizi gözünüzde canlandırın.",
        text22: "• Beklentilerinizi karşılayamadığınız zaman yada başarısız olduğunuzda kendinizi affedin."
    }
];

class Kurban extends Component {
    componentDidMount() {
        const text = `Kurban: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="PageItemHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <p>{lItem.text1}</p>
                    <img
                        src={KurbanImg}
                        alt={lItem.header}
                        width="40%"
                        height="auto"
                        align="left"
                        hspace="20"
                        vspace="5"
                    />
                    <p>{lItem.text2}</p>
                    <p>{lItem.text3}</p>
                    <p>{lItem.text4}</p>
                    <p>{lItem.text5}</p>
                    <p>{lItem.text6}</p>
                    <p>{lItem.text7}</p>
                    <p>{lItem.text8}</p>
                    <p>{lItem.text9}</p>
                    <p>{lItem.text10}</p>
                    <p>{lItem.text11}</p>
                    <p>{lItem.text12}</p>
                    <p>{lItem.text13}</p>
                    <p>{lItem.text14}</p>
                    <p>{lItem.text15}</p>
                    <p>{lItem.text16}</p>
                    <p>{lItem.text17}</p>
                    <p>{lItem.text18}</p>
                    <p>{lItem.text19}</p>
                    <p>{lItem.text20}</p>
                    <p>{lItem.text21}</p>
                    <p>{lItem.text22}</p>
                    <p>Vicdan Merter CPCC, PCC, ACTC</p>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Kurban);