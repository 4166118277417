const ACCOUNT = {
    FETCH: 'ACCOUNT_FETCH',
    FETCH_ERROR: 'ACCOUNT_FETCH_ERROR',
    FETCH_SUCCESS: 'ACCOUNT_FETCH_SUCCESS',
    FETCH_LOGOUT_SUCCESS: 'ACCOUNT_FETCH_LOGOUT_SUCCESS',
    FETCH_AUTHENTICATED_SUCCESS: 'ACCOUNT_FETCH_AUTHENTICATED_SUCCESS',
    UPDATE: 'ACCOUNT_UPDATE',
    UPDATE_ERROR: 'ACCOUNT_UPDATE_ERROR',
    UPDATE_SUCCESS: 'ACCOUNT_UPDATE_SUCCESS',
    UPDATEABONE: 'ACCOUNT_UPDATEABONE',
    UPDATEABONE_ERROR: 'ACCOUNT_UPDATEABONE_ERROR',
    UPDATEABONE_SUCCESS: 'ACCOUNT_UPDATEABONE_SUCCESS',
    ACTIVATE: 'ACCOUNT_ACTIVATE',
    ACTIVATE_ERROR: 'ACCOUNT_ACTIVATE_ERROR',
    ACTIVATE_SUCCESS: 'ACCOUNT_ACTIVATE_SUCCESS',
    UPDATEDISCLAIMER: 'ACCOUNT_UPDATEDISCLAIMER',
    UPDATEDISCLAIMER_ERROR: 'ACCOUNT_UPDATEDISCLAIMER_ERROR',
    UPDATEDISCLAIMER_SUCCESS: 'ACCOUNT_UPDATEDISCLAIMER_SUCCESS',
    FORGOTTENPASSWORD: 'ACCOUNT_FORGOTTENPASSWORD',
    FORGOTTENPASSWORD_ERROR: 'ACCOUNT_FORGOTTENPASSWORD_ERROR',
    FORGOTTENPASSWORD_SUCCESS: 'ACCOUNT_FORGOTTENPASSWORD_SUCCESS',
    SENDACTIVATIONMAIL: 'ACCOUNT_SENDACTIVATIONMAIL',
    SENDACTIVATIONMAIL_ERROR: 'ACCOUNT_SENDACTIVATIONMAIL_ERROR',
    SENDACTIVATIONMAIL_SUCCESS: 'ACCOUNT_SENDACTIVATIONMAIL_SUCCESS',
    INVOKELOGGER: 'ACCOUNT_INVOKELOGGER',
    INVOKELOGGER_ERROR: 'ACCOUNT_INVOKELOGGER_ERROR',
    INVOKELOGGER_SUCCESS: 'ACCOUNT_INVOKELOGGER_SUCCESS',
    SENDCONTACTFORM: 'ACCOUNT_SENDCONTACTFORM',
    SENDCONTACTFORM_ERROR: 'ACCOUNT_SENDCONTACTFORM_ERROR',
    SENDCONTACTFORM_SUCCESS: 'ACCOUNT_SENDCONTACTFORM_SUCCESS',
    WINDOWCHECK: 'ACCOUNT_WINDOWCHECK',
    WINDOWCHECK_ERROR: 'ACCOUNT_WINDOWCHECK_ERROR',
    WINDOWCHECK_SUCCESS: 'ACCOUNT_WINDOWCHECK_SUCCESS'
};

export { ACCOUNT };