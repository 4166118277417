import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import Ruanda1Img from '../images/Ruanda1.jpg';
import Ruanda2Img from '../images/Ruanda2.jpg';

const lList = [
    {
        id: "EN",
        header: "I Support Humanium in Rwanda!",
        text1: "In March 2018 I spent 1 week supporting Humanium in their child rights implementation project in Rwanda.",
        text2: "Humanium contributes to the development and protection of children in Rwanda.",
        text3: "There are 8 children's rights that focus on the situation of children and their most basic rights. Humanium has created a 'Map on the Reflection of Children's Rights Worldwide' and Rwanda has the Color Red, which shows a difficult situation for children.",
        text4: "This project reached 300 people with 18 workshops and I supported Humanium with 8 workshops in Ruhango and Nyanza region for a week. This group; The groups organized by AVSI, the local partner of Humanium, consisted of Community leaders, namely Village Headmen, Friends and Family members, Teachers, Secondary and High School Class presidents and mothers of children.",
        text5: "Depending on the group we facilitated, one or two issues came up as the main child rights issue.",
        text6: "The session I had the most emotional difficulty was with the mothers of children who experienced severe traumas; Those who are mothers at the age of 15-16 and have to take care of their babies only with the support of AVSI. Those child mothers are very positive, they have a lot of hope, they have ambitions and they want to move forward. The 3 role plays performed showed that they left their past stories aside and started to dream in order to make their lives more vigorous and more positive.",
        text7: "I most enjoyed the session with delegates of 31 children representing Nyanza, Gasoro School. They were very enthusiastic and participatory with great enthusiasm and curiosity. In addition to the right to expression, how to find talents and develop them in challenging environments was also widely discussed.",
        text8: "I have had a wonderful experience of relating to wonderful people, even if they are suffering from the circumstances they are in.",
        text9: "The closing of the workshop with children's mothers full of energy and hope for the future was great!"
    },
    {
        id: "TR",
        header: "Ruanda'da Humanium'u Destekliyorum!",
        text1: "Mart 2018'de Ruanda'da çocuk haklarının hayata geçirilmesi projesinde Humanium'u desteklemek için 1 hafta geçirdim.",
        text2: "Humanium, Ruanda'daki çocukların gelişimine ve korunmasına katkıda bulunuyor.",
        text3: "Çocukların durumuna ve en temel haklarına odaklanan 8 çocuk hakkı vardır. Humanium, 'Dünya Çapında Çocuk Haklarının Yansıması Üzerine Harita' oluşturmuştur ve Ruanda, çocuklar için zor bir durumu gösteren Kırmızı Renk'e sahiptir.",
        text4: "Bu proje 18 atölye ile 300 kişiye ulaştı ve ben bir hafta boyunca Ruhango ve Nyanza bölgesinde 8 atölye ile Humanium'a destek verdim. Bu grup; Humanium'un yerel ortağı olan AVSI tarafından organize edilen gruplar, Topluluk liderleri, yani Köy Muhtarları, Arkadaşlar ve Aile üyeleri, Öğretmenler, Ortaokul ve Lise Sınıf başkanları ve çocuk annelerden oluşmaktaydı.",
        text5: "Kolaylaştırdığımız gruba bağlı olarak bir veya iki konu ana çocuk hakları sorunu olarak geldi.",
        text6: "Duygusal olarak en zorlandığım seans, ağır travmalar yaşayan çocuk annelerle oldu; 15-16 yaşında anne olan ve sadece AVSI desteği ile bebeklerine bakmak zorunda kalanlar. O çocuk anneler çok pozitifler, çok umutları var, hırsları var ve ilerlemek istiyorlar. Gerçekleştirilen 3 rol oyunu, geçmiş hikayelerini bir kenara bırakıp hayatlarını daha dinç ve daha pozitif hale getirmek için hayal kurmaya başladıklarını gösterdi.",
        text7: "Nyanza, Gasoro Okulu'nu temsil eden 31 çocuktan oluşan delegelerle yapılan oturumdan en çok keyif aldım. Büyük bir coşku ve merakla çok hevesli ve katılımcıydılar. İfade hakkının yanı sıra yeteneklerinin nasıl bulunacağı ve zorlu ortamlarda nasıl geliştirileceği de geniş çapta tartışıldı.",
        text8: "İçinde bulundukları koşullardan muzdarip olsalar bile harika insanlarla ilişki kurma konusunda harika bir deneyimim oldu.",
        text9: "Enerji dolu ve geleceğe dair umut dolu çocuk annelerle atölyenin kapanışı müthişti!"
    }
];

class Ruanda extends Component {
    componentDidMount() {
        const text = `Ruanda: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="PageItemHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <p>{lItem.text1}</p>
                    <p>{lItem.text2}</p>
                    <img
                        src={Ruanda1Img}
                        alt={lItem.header}
                        width="40%"
                        height="auto"
                        align="left"
                        hspace="20"
                        vspace="5"
                    />
                    <p>{lItem.text3}</p>
                    <p>{lItem.text4}</p>
                    <p>{lItem.text5}</p>
                    <p>{lItem.text6}</p>
                    <p>{lItem.text7}</p>
                   
                    <p>{lItem.text8}</p>
                    <p>{lItem.text9}</p>
                    <img
                        src={Ruanda2Img}
                        alt={lItem.header}
                        width="40%"
                        height="auto"
                        align="center"
                        hspace="20"
                        vspace="5"
                    />
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Ruanda);