import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import FarkindalikImg from '../images/farkinda.png';

const lList = [
    {
        id: "EN",
        header: "Self Awareness",
        text1: "In this article, I will be talking about individual awareness, which is one of the most important steps in the path of leadership, and how it can be developed.",
        text2: "Self-awareness is to know one's self first and to know his/her strengths and weaknesses; can be summarized as using this awareness for a more effective performance. So why is this important?",
        text3: "American activist, poet and writer Maya Angelou, who passed away on May 29, says, 'You have to know well in order to do good.' Research shows that people who see themselves as others see them perform better, while people who exaggerate their skills and think they have more skills than they really are are generally evaluated as underperforming. In other words, the first step to be successful in private and business life is to know, realize and accept in which areas you are good, medium or bad.",
        text4: "There is no one who has not heard of Socrat's saying 'One should know oneself'. This question is an important one, but it immediately prompts a new question; 'but how?'",
        text5: "As I mentioned above, the first step is to be aware of everyone's strengths and weaknesses. On the contrary, if you do not know your strengths and weaknesses, your 'blind spots' will increase; that is, you see yourself as perfect, but others do not share the same opinion with you. This is a deadly attitude; because with this point of view, it is not possible for him to develop this area because he is not aware of the existence of a weak point or because the person cannot admit to himself that he is inadequate in this area. A vicious circle ensues.",
        text6: "Get feedback!",
        text7: "In order to break this cycle, it is necessary to be open to receiving feedback. While you can proactively request feedback, it is important to listen to the criticisms made against you openly. For example, getting feedback from different groups you are in contact with in the work environment sheds light on your strengths and weaknesses. From your boss about your strategic point of view and your decision making skills, from your customer about understanding and sensitivity to customer needs, from your colleagues about cooperation and keeping promises; You get clear feedback from your staff on your leadership approach, delegation or accessibility. When receiving feedback, it is necessary not to get defensive and not to make excuses. This behavior makes the already difficult task of giving feedback unpleasant and discourages the other person's desire. Whatever the content of the feedback, it is necessary to listen and accept it. Remember, every feedback is actually a “gift”. Accept it with thanks and think about it when you buy this gift; What can I learn from this if only 2% of what you say is true? This gives you the opportunity to recognize and improve your blind spots.",
        text8: "Recognize your impact! Reviewing your approach each time to realize your impact in a relationship that is not going well, asking what I could have done differently gives you the courage to try different methods. Why am I perceived this way? Why are my strengths so dominant? Are my weaknesses not improving because I avoid unfamiliar areas or because I don't like these jobs? Such an internal analysis enables us to recognize the good, the bad and the ugly in all of us, and to discover how we can use more by recognizing the good in us. If you think that our blind spots are the most important obstacle in front of our career, your goal should be to eliminate these blind spots.",
        text9: "Realize your assumptions!",
        text10: "Another method is to understand why you judge others. This shows you the behaviors and attitudes that underlie your reactions. For example, make a list of people you like and dislike and try to understand why you feel that way. What are the common features of the people you dislike, what are the features that make you different from them? What are your common/conflicting values? These questions give you clues as to why you treat these people the same way.",
        text11: "Be honest with yourself!",
        text12: "Write down in a diary when you used your strengths in the face of problems, what kind of experience you had when your weak side emerged, what emotions these relationships triggered in you. When you see what works and what doesn't, you have a choice next time. Also, if you write your shortcomings and mistakes clearly in this diary, it will give you the courage to improve these areas. The first step is to allow yourself to appear as you are, to accept yourself as you are. The next step is to actually admit your weak points in front of other people. This creates the perception of a person who knows himself and is open to learning. To improve this skill, choose 3 people who openly share their mistakes and observe how they do it. Also, if this issue is important to you, find a mentor that you trust and cares about your success. Alternatively, 360-degree surveys create an ideal feedback environment for this business.",
        text13: "Do not overdo it!",
        text14: "As our ancestor said, “excess of everything is bad”. Excessive self-awareness also has its downsides. One of them is that someone who constantly criticizes himself seems to have low self-esteem. Or seeking feedback without serving a purpose in all matters or not being able to act without feedback also creates a barrier for career.",
        text15: "HOMEWORK: I have a homework for those who want to improve themselves in this regard. Take steps to mend your relationship with someone with whom your relationship is hostile or with someone you have disappointed in the past. For this, focus on your own share/contribution that created this situation, not what they say and do. So first we have to prick ourselves with the needle. ☺ Consider the following questions before taking action.",
        text16: "What was the situation? What did you do and why did you do it? Why did you choose this approach? What did you learn from this experience? What does this experience tell you about your self-awareness?",
        text17: "Pros",
        text18: "• S/he is aware of her strengths and weaknesses and is sincere.",
        text19: "• The feedback given to her/him does not come as a surprise",
        text20: "• Finds criticism helpful",
        text21: "• Admits and owns mistakes",
        text22: "• Knows what creates your feelings",
        text23: "• Seeks and receives feedback",
        text24: "• Learns from past mistakes",
        text25: "• S/he is content with herself",
        text26: "• Focuses on now",
        text27: "• Values those who accept people as they are",
        text28: "Cons",
        text29: "• S/he thinks she knows herself better than anyone.",
        text30: "• Does not publicly share their views",
        text31: "• They have high self-confidence",
        text32: "• Doesn't care about other people's opinions"
    },
    {
        id: "TR",
        header: "Bireysel Farkındalık",
        text1: "Bu yazımda size liderlik yolunda önemli basamaklardan biri olan hatta en önemlisi diyebileceğim bireysel farkındalıktan ve nasıl geliştirilebileceğinden bahsediyor olacağım.",
        text2: "Bireysel farkındalık kişinin öncelikle kendini tanıması ve kendisinin güçlü olduğu ve zayıf olduğu yönlerini bilmesi; bu farkındalığını daha etkili bir performans için kullanması olarak özetlenebilir. Peki bu niçin önemli?",
        text3: "29 Mayısta hayata veda eden amerikalı aktivist, şair ve yazar Maya Angelou “İyi birşey yapabilmek için iyi bilmen lazım” diyor. Araştırmalar ise kendisini başkalarının gördüğü gibi gören kişilerin daha yüksek performans gösterdiğini, sahip olduğu becerilerini abartan ve olduğundan daha yüksek beceriye sahip olduğunu düşünen kişilerin ise genellikle düşük performansla değerlendirildiklerini gösteriyor. Yani özel yaşamda ve iş yaşamında başarılı olmanın ilk adımı hangi alanlarda iyi, orta yada kötü olduğunuzu bilmek, farketmek ve kabul etmek.",
        text4: "Socratın “Kişi kendini bilmeli” sözünü duymayan yoktur. Bu soru önemli bir soru ancak hemen arkasından yeni bir soruyu sorduruyor; 'peki ama nasıl?'",
        text5: "Yukarıda da değindiğim gibi birinci adım herkesin sahip olduğu güçlü ve zayıf yönlerinin farkında olması… Tam tersi güçlü ve zayıf yönlerinizi bilmezseniz 'kör noktalarınız' çoğalır; yani siz kendinizi kusursuz görürsünüz ama başkaları sizinle aynı fikri paylaşmaz..ben buna bizim kültürümüzde “ben oldum” tutumu diyorum. Bu ölümcül bir tutum; çünkü bu bakış açısı ile zayıf noktanın varlığından haberdar olunmadığı veya kişi bu alanda yetersiz olduğunu kendisine itiraf edemediği için bu alanı geliştirmesi de mümkün olmaz. Tam bir kısır döngü oluşur.",
        text6: "Geribildirim alın!",
        text7: "Bu döngüyü kırmak için ise geri bildirim almaya açık olmak gerekir. Geribildirimi proaktif olarak talep edebileceğiniz gibi size yapılan eleştirileri açıklıkla dinlemek önemli. Örneğin çalışma ortamında ilişkide olduğunuz farklı gruplardan geribildirim almak size güçlü ve zayıf noktalarınızla ilgili ışık tutar. Patronunuzdan startejik bakış açınız ve karar verme beceriniz hakkında , müşterinizden müşteri ihtiyaçlarını anlama ve duyarlılık konusunda , çalışma arkadaşlarınızdan ise işbirliği ve verilen sözlerin tutulması; elemanlarınızdan ise liderlik yaklaşımınız, delegasyon yada erişilebilirlik konusunda net geribildirim alırsınız. Geri bildirimi alırken savunmaya geçmemek ve mazeret öne sürmemek gerekir. Bu davranış zaten zor olan geribildirim verme işini keyifsiz hale getirir ve karşınızdakinin isteğini kırar. Geribildirimin içeriği ne olursa olsun, dinlemek ve kabul etmek gerekir. Unutmayın her geribildirim aslında bir “hediye”dir. Bunun için teşekkürle kabul edin ve bu hediyeyi alırken söyle düşünün; eğer söylediklerinin sadece %2 si doğru olsa ben bundan ne öğrenebilirim? Bu size kör noktalarınızı farketme ve geliştirme fırsatı verir.",
        text8: "Etkinizin farkına varın! Yolunda gitmeyen bir ilişkide etkinizin farkına varmak için her seferinde yaklaşımınızı gözden geçirmek neyi farklı yapabilirdim sorusunu sormak size farklı yöntemleri deneme cesareti verir. Neden böyle algılanıyorum? Güçlü yönlerim neden bu kadar baskın? Zayıf yönlerim bilmediğim alanlardan kaçındığım için mi, yoksa bu işleri sevmediğim için mi gelişmiyor? Böyle bir iç analiz hepimizin içinde olan iyi, kötü ve çirkini tanımamızı ve içimizdeki iyiyi farkederek nasıl daha çok kullanabilirizi keşfetmemizi sağlar. Kör noktalarımızın kariyerimizin önündeki en önemli engel olduğunu düşünürseniz hedefinizin bu kör noktaları ortadan kaldırmak olması gerekir.",
        text9: "Varsayımlarınızı farkedin!",
        text10: "Başka bir yöntem de başkalarını neden yargıladığınızı anlamak. Bu size reaksiyonlarınızın altında yatan davranış ve tutumlarınızı gösterir. Örneğin sevdiğiniz ve sevmediğiniz insanların listesini yapın ve neden böyle hissettiğinizi anlamaya çalışın. Sevmediğiniz insanların ortak özellikleri neler, sizi onlardan farklı kılan özellikleriniz neler? Ortak/çelişen değerleriniz neler? Bu sorular bu kişilere neden aynı şekilde davrandığınız konusunda size ipucu verir.",
        text11: "Kendinize karşı dürüst olun!",
        text12: "Sorunlar karşısında ne zaman güçlü yanınızı kullandığınızı, zayıf kötü yanınız ortaya çıkınca nasıl bir deneyim yaşadığınızı, bu ilişkilerin sizde hangi duyguları tetiklediğini bir günlüğe not edin. Neyin işe yarayıp neyin işe yaramadığını gördüğünüzde bir sonraki sefer seçim şansınız olur. Ayrıca bu günlüğe eksik yönlerinizi ve hatalarınızı açıklıkla yazarsanız bu size bu alanları geliştirme cesareti verecektir. Birinci aşama kendi kendinize olduğunuz gibi görünme izni vermek, kendinizi olduğunuz gibi kabul etmek. Bir sonraki adım ise aslında bu zayıf noktalarınızı başka insanların önünde de kabul etmek. Bu karşı tarafta kendini tanıyan ve öğrenmeye açık bir kişi algısı yaratır. Bu becerinizi geliştirmek için kendi hatalarını açıklıkla paylaşan 3 kişi seçin ve onların bunu nasıl yaptığını gözlemleyin. Ayrıca bu konu sizin için önemliyse kendinize güvendiğiniz sizin başarınızı önemseyen bir mentor bulun. Alternatif olarak 360 derece anketler bu iş için ideal bir geribildirim ortamı yaratır.",
        text13: "Aşırıya kaçmayın!",
        text14: "Ata sözümüzün dediği gibi “herşeyin fazlası zarar”. Aşırı bireysel farkındalığın da zararları var. Sürekli kendini eleştiren birinin özgüveni düşük görünmesi bunlardan biri. Yada herkonuda bir amaca hizmet etmeden geribildirim aramak yada geribildirim almadan hareket edememek de kariyer için bariyer oluşturur.",
        text15: "ÖDEV: Bu konuda kendini geliştirmek isteyenler için bir de ödevim var. İlişkinizin düşmanca olduğu yada geçmişte hayal kırıklığına uğrattığınız biri ile ilişkinizi düzeltmek için adım atın. Bunun için onların ne dediği ve yaptığına değil, bu durumu yaratan kendi payınıza/katkınıza odaklanın. Yani önce iğneyi kendimize batırmak gerekiyor. ☺ Aksiyon almadan önce aşağıdaki soruları düşünün.",
        text16: "Durum neydi? Ne yaptınız ve niçin yaptınız? Bu yaklaşımınızı neden seçtiğiniz?Bu deneyimden ne öğrendiniz? Bu deneyim size bireysel farkındalığınız hakkında ne söylüyor",
        text17: "Artılar",
        text18: "• Güçlü ve zayıf yönlerinin farkında ve samimidir",
        text19: "• Kendisine verilen geribildirim surpriz olmaz",
        text20: "• Eleştiriyi faydalı bulur",
        text21: "• Hatalarını itiraf eder ve sahiplenir",
        text22: "• Duygularını neyin yarattığını bilir",
        text23: "• Geribildirim arar ve alır",
        text24: "• Geçmiş hatalarından öğrenir",
        text25: "• Kendi halinden memnundur",
        text26: "• Şimdiye odaklanır",
        text27: "• Kişileri olduğu gibi kabul edenlere değer verir",
        text28: "Eksiler",
        text29: "• Kendisini herkesten iyi kendisinin tanıdığını düşünür",
        text30: "• Görüşlerini açıka paylaşmaz",
        text31: "• Özgüvenleri yüksektir",
        text32: "• Başkalarının görüşlerini önemsemez"
    }
];

class Farkindalik extends Component {
    componentDidMount() {
        const text = `Farkindalik: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="PageItemHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <p>{lItem.text1}</p>
                    <p>{lItem.text2}</p>
                    <img
                        src={FarkindalikImg}
                        alt={lItem.header}
                        width="40%"
                        height="auto"
                        align="left"
                        hspace="20"
                        vspace="5"
                    />
                    <p>{lItem.text3}</p>
                    <p>{lItem.text4}</p>
                    <p>{lItem.text5}</p>
                    <p>{lItem.text6}</p>
                    <p>{lItem.text7}</p>
                    <p>{lItem.text8}</p>
                    <p>{lItem.text9}</p>
                    <p>{lItem.text10}</p>
                    <p>{lItem.text11}</p>
                    <p>{lItem.text12}</p>
                    <p>{lItem.text13}</p>
                    <p>{lItem.text14}</p>
                    <p>{lItem.text15}</p>
                    <p>{lItem.text16}</p>
                    <p>{lItem.text17}</p>
                    <p>{lItem.text18}</p>
                    <p>{lItem.text19}</p>
                    <p>{lItem.text20}</p>
                    <p>{lItem.text21}</p>
                    <p>{lItem.text22}</p>
                    <p>{lItem.text23}</p>
                    <p>{lItem.text24}</p>
                    <p>{lItem.text25}</p>
                    <p>{lItem.text26}</p>
                    <p>{lItem.text27}</p>
                    <p>{lItem.text28}</p>
                    <p>{lItem.text29}</p>
                    <p>{lItem.text30}</p>
                    <p>{lItem.text31}</p>
                    <p>{lItem.text32}</p>
                    <p>Vicdan Merter CPCC, PCC, ACTC</p>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Farkindalik);