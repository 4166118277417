import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import IKImg from '../images/dragonfly.jpg';

const lList = [
    {
        id: "EN",
        header: "Human Resources Consulting",
        text1: "In today's world, human resources have become the most important capital and almost the only competitive element due to the fact that information and technology are easily accessible and copyable. Human is the most critical resource for high performance and sustainable success.",
        text2: "Consultancy services are provided in the fields specified in the scope for the needs of institutions in the field of Human Resources in the fields of installation, improvement or development. These fields can be grouped under the following headings:",
        text3: "➢ Corporate Culture Studies",
        text4: "➢ Organizational Change and Transformation",
        text5: "➢ Determination of HR Strategy and Establishment of HR Processes",
        text6: "➢ Workforce Planning",
        text7: "➢ Performance management",
        text8: "➢ Salary and Benefits Management",
        text9: "➢ Training and Development Management",
        text10: "➢ Talent Management, Career and Backup"
    },
    {
        id: "TR",
        header: "İnsan Kaynakları Danışmanlığı",
        text1: "Günümüz dünyasında bilginin ve teknolojinin kolay erişilebilir ve kopyalanabilir olması dolayısı ile insan kaynağı en önemli sermaye ve neredeyse tek rekabet unsuru haline gelmiştir. Yüksek performans ve sürdürülebilir başarı için insan en kritik kaynak olarak karşımıza çıkmaktadır.",
        text2: "Kurumların İnsan Kaynakları alanında kurulum, iyileştirme ya da geliştirme alanlarındaki ihtiyaçlarına yönelik kapsamda belirtilen alanlarda danışmanlık hizmeti verilmektedir. Bu alanlar şu başlıklarda toplanabilir:",
        text3: "➢ Kurum Kültürü Çalışmaları",
        text4: "➢ Organizasyonel Değişim ve Dönüşüm",
        text5: "➢ İK Startejisinin Belirlenmesi ve İK Süreçlerinin Kurulumu",
        text6: "➢ İş Gücü Planlama",
        text7: "➢ Performans Yönetimi",
        text8: "➢ Ücret ve Yan Haklar Yönetimi",
        text9: "➢ Eğitim ve Gelişim Yönetimi",
        text10: "➢ Yetenek Yönetimi, Kariyer ve Yedekleme"
    }
];

class IK extends Component {
    componentDidMount() {
        const text = `IK: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="PageItemHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <p>{lItem.text1}</p>
                    <img
                        src={IKImg}
                        alt={lItem.header}
                        width="40%"
                        height="auto"
                        align="left"
                        hspace="20"
                        vspace="5"
                    />
                    <p>{lItem.text2}</p>
                    <p>{lItem.text3}</p>
                    <p>{lItem.text4}</p>
                    <p>{lItem.text5}</p>
                    <p>{lItem.text6}</p>
                    <p>{lItem.text7}</p>
                    <p>{lItem.text8}</p>
                    <p>{lItem.text9}</p>
                    <p>{lItem.text10}</p>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(IK);