import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
//import queryString from 'query-string';
import { signup,
         login,
         activateAccount,
         forgottenPassword } from './actions/account';
import fetchStates from './reducers/fetchStates';

const lList = [
    {
        id: "EN",
        textUser: "E-Mail",
        placeUser: "Please enter your email address",
        textPassword: "Password",
        placePassword: "Please enter your password",
        connecting: "Connecting to server",
        signIn: "Sign-in",
        signUp: "Register",
        forgot: "Forgot Password",
        userError1: "Please enter your e-mail and password",
        userError2: "Please enter your password",
        userError3: "Please enter your e-mail address including the '@' character",
        userError4: "Please enter your e-mail address including the '.' character",
        userError5: "Please enter your e-mail address registered in our database",
        userError6: "Please enter your e-mail and password, then create your registration by pressing the 'Register' button.",
        userError7: "Please press the 'Forgot Password' button after entering your e-mail address.",
        error409: "Your e-mail and/or password do not match the information in our database.",
        error410: "You need to activate your account. A message about address verification has been sent to your e-mail address. Please check your mailbox. After activating your account, you can log in to the system."
    },
    {
        id: "TR",
        textUser: "E-Posta",
        placeUser: "Lütfen e-posta adresinizi giriniz",
        textPassword: "Şifre",
        placePassword: "Lütfen şifrenizi giriniz",
        connecting: "Sunucuya bağlanıyorum",
        signIn: "Giriş",
        signUp: "Yeni Kayıt",
        forgot: "Şifremi Unuttum",
        userError1: "Lütfen e-posta ve şifrenizi giriniz",
        userError2: "Lütfen şifrenizi giriniz",
        userError3: "Lütfen '@' karakterini de içeren e-posta adresinizi giriniz",
        userError4: "Lütfen '.' karakterini de içeren e-posta adresinizi giriniz",
        userError5: "Lütfen sistemimizde kayıtlı olan e-posta adresinizi giriniz",
        userError6: "Lütfen e-posta ve şifrenizi giriniz, sonra 'Yeni Kayıt' tuşuna basarak kaydınızı oluşturunuz",
        userError7: "Lütfen e-posta adresinizi girdikten sonra 'Şifremi Unuttum' tuşuna basınız",
        error409: "E-posta ve/veya şifreniz kayıtlarımızdaki bilgilere uymuyor",
        error410: "Hesabınızı aktive etmeniz gerekmektedir. E-Posta adresinize adres doğrulama ile ilgili ileti gönderilmiştir. Lütfen posta kutunuzu kontrol ediniz. Hesabınızı aktifleştirdikten sonra sisteme giriş yapabilirsiniz."
    }
];

class Giris extends Component {
    state = {
        username: '',
        password: '',
        buttonClicked: false,
        usernameError: ''
    }

    updateUsername = event => {
        this.setState({
            username: event.target.value.toLowerCase(),
            usernameError: ''
        });
    }

    updatePassword = event => {
        this.setState({
            password: event.target.value,
            usernameError: ''
        });
    }

    keyPress = (e) => {
        if (e.key === 'Enter')
            this.login();
    }

    signup = () => {
        const { username, password } = this.state;

        this.setState({ buttonClicked: false });
        if (username === '') {
            this.setState({ usernameError: "userError6" });
            return;
        } else {
            if (this.ePostaKontrol(username)) {
                if (password === '') {
                    this.setState({ usernameError: "userError2" });
                    return;
                }
    
                this.setState({ buttonClicked: true });
                this.props.signup({ username, password });
                this.setState({ username: '', password: '' })
            }
        }
    }

    login = () => {
        const { username, password } = this.state;

        this.setState({ buttonClicked: false });
        if (username === '') {
            this.setState({ usernameError: "userError1" });
            return;
        } else {
            if (this.ePostaKontrol(username)) {
                if (password === '') {
                    this.setState({ usernameError: "userError2" });
                    return;
                }
                this.setState({ buttonClicked: true });
                this.props.login({ username, password });
                this.setState({ username: '', password: '' })
            }
        }
    }

    forgottenPassword = () => {
        const { username } = this.state;

        this.setState({ buttonClicked: false });
        if (username === '') {
            this.setState({ usernameError: "userError7" });
            return;
        } else {
            if (this.ePostaKontrol(username)) {    
                this.setState({ buttonClicked: true });
                this.props.forgottenPassword({ username });
            }
        }
    }

    statusCodeText(statusCode) {
        let lItem = lList.find((item) => { return item.id === this.props.language });
        let errorMessage = lItem["error"+statusCode];
        if (errorMessage === undefined)
            errorMessage = statusCode;
            
        return <div>{errorMessage}</div>
    }

    Error() {
        let lItem = lList.find((item) => { return item.id === this.props.language });
        if (this.state.buttonClicked) {
            if (this.props.account.status === fetchStates.error ||
                this.props.account.status === fetchStates.success) {
                return this.statusCodeText(this.props.account.statusCode)
            } 
        }

        if (this.state.usernameError !== '')
            return <div>{lItem[this.state.usernameError]}</div>
        
        return null
    }

    ePostaKontrol(ePosta) {
        let pos = ePosta.indexOf("@");

        if (pos === -1) {
            this.setState({ usernameError: "userError3" });
            return false;
        }

        let str = ePosta.substring(pos+1);

        pos = str.indexOf(".");
        if (pos < 0) {
            this.setState({ usernameError: "userError4" });
            return false;
        }

        if (pos === 0 || pos === str.length-1) {
            this.setState({ usernameError: "userError5" });
            return false;
        }

        return true;
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="Giris">
                    <div className="PageInput">
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ "width" : '90px' }}>{lItem.textUser}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="EMail"
                                value={this.state.username}
                                onChange={this.updateUsername}
                                onKeyPress={this.keyPress}
                                placeholder={lItem.placeUser}
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ "width" : '90px' }}>{lItem.textPassword}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="Şifre"
                                value={this.state.password}
                                onChange={this.updatePassword}
                                onKeyPress={this.keyPress}
                                placeholder={lItem.placePassword}
                                type="password"
                            />
                        </InputGroup>
                    </div>
                    <div className="Warning ">
                    {
                        this.props.account.status === fetchStates.fetching ?
                            lItem.connecting
                         : this.Error()
                    }
                    </div>
                    {
                        this.props.account.status !== fetchStates.fetching ?
                        <div className="PageInput">
                            <Button variant="success" onClick={this.login}>{lItem.signIn}</Button>
                            <span>&nbsp;</span>
                            <Button variant="warning" onClick={this.signup}>{lItem.signUp}</Button>
                            <span>&nbsp;</span>
                            <Button variant="danger" onClick={this.forgottenPassword}>{lItem.forgot}</Button>
                        </div> : null
                    }
                </div>
            </div>
        )        
    }
}

export default connect(
    ({ account }) => ({ account }),
    { signup, login, activateAccount, forgottenPassword }
)(Giris);