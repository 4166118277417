import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import KoclukImg from '../images/stones.jpg';

const lList = [
    {
        id: "EN",
        header: "Coaching",
        text: [
            "What is Coaching?",
            "Coaching is more than just a methodology; it is a unique relationship model. It is a partnering relationship between two equal parties based on the client's agenda and encompasses the client's entire life. Coaching is a proactive strategy for personal and professional development. A coaching session is a dialogue between the coach and the client, focusing on helping the client discover their own answers. The foundation of effective coaching is built on trust, integrity, and skill.",
            "What Coaching is Not?",
            "Coaching is not consulting, therapy, or mentoring. Consultants and mentors share their experiences, knowledge, and expertise by offering advice and recommendations. In contrast, coaches do not give advice or focus on past psychological issues as therapists do. Coaching works in the present, empowering individuals to take action on matters they care about and preparing them for the future.",
            "Our Coaching Services",
            "➢ Executive Coaching",
            "This is a specialized coaching service focused on enhancing the leadership skills, professional development, and performance of executives and senior leaders within organizations.",
            "➢ Career Coaching",
            "Providing personalized guidance, this service assists in directing your career journey, achieving professional goals, and finding work-life balance.",
            "➢ First 90 Days Coaching",
            "Specifically designed to help individuals adapt effectively and succeed in their new role during the initial 90 days. This service ensures a swift and effective start in your new position.",
            "➢ Expat Coaching",
            "Tailored for professionals working abroad, this service facilitates their adjustment to a different culture and work environment. Expat coaching supports cultural awareness, personal, and professional adaptation processes to enhance your success overseas.",
        ],
    },
    {
        id: "TR",
        header: "Koçluk",
        text: [
            "Koçluk Nedir?",
            "Koçluk bir metodoloji olmaktan çok kendine özgü bir ilişki modelidir. Bu ilişki danışanın gündemine göre iki eşit tarafın işbirliğidir ve danışanın tüm yaşamını ele alır. Bireylerin hedeflerini ve isteklerini keşfetmelerine ve gerçekleştirmelerine yardımcı olan koçluk seansı, koç ile danışan arasında geçen ve danışanın kendi cevaplarını bulmasına odaklanan bir diyaloğdur. Koçluk, kişisel ve profesyonel gelişim için proaktif bir stratejidir. İyi bir koçluğun temeli güven, bütünlük ve beceri üzerine kurulur.",
            "Koçluk Ne Değildir?",
            "Koçluk, danışmanlık, terapi ya da mentorluk değildir. Danışman ve mentorlar kendi deneyim, bilgi ve tecrübelerini paylaşarak tavsiye ve önerilerde bulunurlar. Danışmanlar ve mentorlar, deneyimlerini, bilgilerini ve uzmanlıklarını paylaşarak tavsiye ve önerilerde bulunurlar.",
            "Buna karşılık, koçlar tavsiye vermez veya terapistlerin yaptığı gibi geçmiş psikolojik sorunlara odaklanmaz. Koçluk, şimdiki zamanda çalışır, bireyleri önemsedikleri konularda harekete geçmeleri için güçlendirir ve onları geleceğe hazırlar. Koçluk şimdiki zamanda çalışarak kişiyi hayatında uğraşmakta olduğu ve gerçekten önemsediği konularla ilgili güçlendirerek aksiyon almaya yönlendirir ve geleceğe hazırlar.",
            "Koçluk Hizmetlerimiz:",
            "➢ Yönetici Koçluğu",
            "Organizasyonlardaki yöneticilerin ve üst düzey liderlerin liderlik becerilerini, profesyonel gelişimini ve performansını artırmaya odaklanan özel bir koçluk hizmetidir.",
            "➢ Kariyer Koçluğu",
            "Kariyer yolculuğunuzu yönlendirmenize, profesyonel hedeflerinize ulaşmanıza ve iş yaşam dengenizi bulmanıza yardımcı olmak için kişiselleştirilmiş bir hizmeti içerir.",
            "➢ İlk 90 Gün Koçluğu",
            "Yeni bir role geçen bireylerin, ilk 90 günlerinde etkin bir şekilde adapte olmalarına ve başarıya ulaşmalarına yardımcı olmak için özel olarak tasarlanmış bir koçluk programıdır. Bu hizmet, yeni görevinizde hızlı ve etkili bir başlangıç yapmanızı sağlar.",
            "➢ Expat Koçluğu",
            "Yurt dışında çalışan profesyoneller için sunulan bu hizmet, farklı bir kültüre ve çalışma ortamına uyum sağlamalarını kolaylaştırır. Expat koçluğu, yurtdışındaki başarınızı artırmak için kültürel farkındalık, kişisel ve profesyonel adaptasyon süreçlerini destekler.",
        ],
    }
];

class Kocluk extends Component {
    componentDidMount() {
        const text = `Kocluk: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="PageItemHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <img
                        src={KoclukImg}
                        alt={lItem.header}
                        width="40%"
                        height="auto"
                        align="left"
                        hspace="20"
                        vspace="5"
                    />
                    <div>
                    {
                        lItem.text.map((text,i) => (
                            <p>{text}</p>
                        ))
                    }
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Kocluk);