import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Menu from './Menu';
import MainImage from './MainImage';
//import Banner from './Banner';
import ContactForm from './ContactForm';
import Links from './Links';
import Footer from './Footer';
import Giris from './Giris';
import Takvim from './Takvim';
import Member from './Member';
import Profilim from './Profilim';
import WoLife from './WoLife';

import Home from './components/Home';
import Yazilar from './components/Yazilar';
import Hakkimda from './components/Hakkimda';
import ContactFormFiled from './ContactFormFiled';

class App extends Component {
  state = {
    language: this.getLanguage(),
    visible: (localStorage.getItem("cookiesAccepted") === "Y" ? false : true),
    contactFormSent: false
  }

  getLanguage() {
    let language = localStorage.getItem("userLanguage");
    switch(language) {
      case 'EN':
      case 'TR': 
        return language;
      default:
        return 'EN';
    }
  }

  changeLanguage = (language) => {
    this.setState({ language });
  }

  changeVisible = (visible) => {
    this.setState({ visible });
  }

  changeContactFormSent = (contactFormSent) => {
    this.setState({ contactFormSent });
  }

  render() {
    return (
        <BrowserRouter>
          <Menu
            language={this.state.language}
            changeLanguage={this.changeLanguage}/>
          {
          /*  this.props.account.loggedIn ?
            <Member language={this.state.language} /> :
              <Route path='/giris'>
                <Giris
                  language={this.state.language} />
              </Route>
          */
          }
          <MainImage />
          <Switch>
            <Route path='/wol'>
              <WoLife language={this.state.language} />
            </Route>
            <Route path='/profilim'>
              <Profilim language={this.state.language} />
            </Route>
            <Route path='/hakkimda'>
              <Hakkimda language={this.state.language} />
            </Route>
            <Route path='/yazilar'>
              <Yazilar language={this.state.language} />
            </Route>
            <Route path='/takvim'>
              <Takvim language={this.state.language} />
            </Route>
            <Route path='/iletisim-formu-alindi'>
              <ContactFormFiled
                language={this.state.language}
                changeContactFormSent={this.changeContactFormSent}/>
            </Route>
            <Route path='/'>
              <Home language={this.state.language} />
            </Route>
            <Route>
              <Home language={this.state.language} />
            </Route>
          </Switch>
          {/*<Banner language={this.state.language} />*/}
          <ContactForm language={this.state.language} />
          <Links language={this.state.language} />
          <Footer language={this.state.language} />
        </BrowserRouter>
    )
  }
}

export default connect(
  ({ account }) => ({ account }),
  null
)(App);
