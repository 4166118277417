import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import MahserImg from '../images/mahser.jpeg';

const lList = [
    {
        id: "EN",
        header: "The Four Horsemen of the Apocalypse",
        text1: "The Four Horsemen of the Apocalypse are the 4 horsemen mentioned in the Bible, believed to appear before the apocalypse and marking the end of the world. They represent defeat, war, hunger, and death, respectively. Dr. Gottman uses this metaphor for his communication style that ends relationships.",
        text2: "When I look back at the 20th year of my professional life, I saw that relationship management is very important in both private and business life. I can even say that relationship management is one of the most important leadership skills.",
        text3: "The famous American psychologist and relationship master John Gottman found that there are negative behaviors that he called the 4 horsemen of the apocalypse, which have a lethal effect on relationships, especially in his studies on married couples. In fact, Gottman predicts with a 91% accuracy that couples will get divorced as a result of his research with couples who have been married for 16 years. For this, Gottman also looks at other factors such as the way of starting conflict, body language, in addition to the 4 behaviors he underlines as the 4 horsemen of the apocalypse. This work, which Gottman called the 4 horsemen of the apocalypse, affects not only the relationships of married couples, but also relationships in professional life, so we will be including it in this article.",
        text4: "In studies with organizations and teams, we call these negative behaviors that negatively affect relationships as toxic behavior. The horseshoes of the 4 horsemen, which negatively affect the relations, are usually heard in the following order; blame, contempt, defense and wall building.",
        text5: "Blame: We all complain from time to time about the people we're with, it's perfectly normal. But there is a world of difference between complaining and accusing. Complaining focuses on a behavior and is action-oriented. Blaming is more general and includes negative remarks about the person's character/personality. The downside is that when used frequently, it adds the other, more deadly, horseman to the game.",
        text6: "Example: instead of “You are only talking about yourself, you are very selfish”..",
        text7: "“I think I was neglected in our conversation tonight, can we talk about how I spend my day too?”",
        text8: "The antidote to blame is to focus on the behavior, not the person. It's important to define the situation without judgment and try to see the need that lies behind the accusation.",
        text9: "Contempt: Contempt includes sarcasm, name-calling, contempt, ridicule, and humiliation. Contempt, the worst of the four horsemen, poisons the relationship because it implies disgust. Humiliating comments and words pollute the atmosphere in the relationship.",
        text10: "Example: Instead of 'I think what you do best is to spread out without doing anything'",
        text11: "“It bothers me a lot that you come home and do nothing, the table is still not cleared.”",
        text12: "The person who brings contempt and contempt should be reminded to stop. If you are displaying this behavior yourself, consider that everyone deserves innate respect and remember that humiliation can have a devastating effect on your relationship. So much so that the rate of people who are despised to get infectious diseases is higher than that of other people.",
        text13: "Defense: While it's understandable to be on the defensive when criticized, it's useless to defend. Because, in fact, defense is a kind of accusation. It's not my fault, it's your fault, and it escalates the conflict. The solution to the accusation is to listen, thinking that the complainant has 2% of right. Using I language instead of you language and taking responsibility by being aware of our own emotions increases positivity.",
        text14: "Example: Instead of 'You're the reason we're late, not me'",
        text15: "“I had to watch the time, that's my responsibility too.”",
        text16: "Walling: In relationships where arguments lead to increasing contempt and self-defense, one of the parties cuts off communication, this is the harbinger of the fourth horseman. By building a wall, the person closes himself with communication and does not say what he thinks. Especially in environments where the other 3 horsemen are running, team members want to get rid of the situation by building a Wall, but this increases blame and contempt. Wall knitting is a method preferred mostly by men.",
        text17: "Taking a break from the discussion instead of building a wall and closing is the best method, it helps the person to calm themselves down and start the discussion from a more positive place.",
        text18: "So is there a solution to this?",
        text19: "It is normal to have conflict in any kind of relationship. What matters is what you do when conflict arises. According to Gottman, these behaviors usually occur when people feel helpless and powerless or in situations where there is fear. But there is no need to be afraid. Gottman also shares his antidote with us.",
        text20: "• The first step of the solution is to educate people about the 4 horsemen of the apocalypse in order to increase positivity in communication in teams. Thus, when a team member makes toxic communication by bringing one of the 4 horsemen into a relationship, naming it by other team members can increase awareness and reduce escalation.",
        text21: "• According to Gottman, if one of the above toxic behaviors occurs in a relationship, it is necessary to say at least 5 positive things to break the negativity in the relationship. In other words, respect and appreciation prevent the relationship from being crushed by the 4 horsemen. If you look carefully, I'm sure you'll find something that everyone appreciates.",
        text22: "• If there is a situation that you feel uncomfortable in your relationship with your spouse or in the team, voice your complaint by requesting it. (X happened, I felt Y, I want Z)",
        text23: "• Recognize your assumptions and let go of them. Be a good listener.",
        text24: "• Calm yourself down, pause the discussion if necessary.",
        text25: "Now you will realize which of the 4 horsemen of the apocalypse comes running to your relationships where you have conflict and its effect on your relationship. It's up to you whether the apocalypse will break out in your relationship :)",
        text26: "We wish you to establish and maintain positive and productive individual and team relationships."
    },
    {
        id: "TR",
        header: "Mahşerin Dört Atlısı",
        text1: "Mahşerin Dört Atlısı, İncil’de adları geçen, kıyamet öncesinde ortaya çıkacaklarına inanılan ve dünyanın sonunu işaret eden 4 atlı. Sırasıyla yenilgi, savaş, açlık ve ölümü temsil ediyorlar. Dr. Gottman ilişkileri sona erdiren iletişim stili için bu metaforu kullanıyor.",
        text2: "Profesyonel hayatımın 20. Yılına girdiğim bir dönemde geriye dönüp baktığımda hem özel hayat hem de iş hayatında ilişki yönetiminin çok önemli olduğunu gördüm. Hatta ilişki yönetiminin en önemli liderlik becerilerinden biri olduğunu söyleyebilirim.",
        text3: "Amerikalı ünlü psikolog ve ilişki ustası John Gottman, özellikle evli çiftlerle ilgili çalışmalarında ilişkilere öldürücü etkisi olan ve mahşerin 4 atlısı olarak isimlendirdiği negatif davranışlar olduğunu bulmuş. Hatta gottman 16 yıllık evli çiftlerle yaptığı araştırmalar sonucunda %91 lık bir doğruluk payı ile çiftlerin boşanacağını tahmin ediyor.. Bunun için Gottman mahşerin 4 atlısı olarak altını çizdiği 4 davranışın yanısıra çatışmaya başlama biçimi, vücüt dili gibi başka unsurlara da bakıyor. Gottman’ın mahşerin 4 atlısı olarak isimlendirdiği bu çalışma sadece evli çiftlerin ilişkilerini değil profesyonel yaşamdaki ilişkileri de olumuz etkilediği için bu yazıda yer veriyor olacağız.",
        text4: "Organizasyonlarla ve takımlarla yapılan çalışmalarda ilişkileri olumsuz etkileyen bu negatif davranışları toksik davranış olarak isimlendiriyoruz. İlişkileri olumsuz etkileyen 4 atlının nal sesleri genellikle şu sıra ile duyuluyor; suçlama, hor görme, savunma ve duvar örme.",
        text5: "Suçlama:Birlikte olduğumuz kişiler hakkında hepimiz zaman zaman yakınırız, bu çok normaldir. Ancak yakınma ile suçlama arasında dünya kadar fark vardır. Yakınma bir davranış üzerine odaklanır ve eyleme yöneliktir. Suçlama ise daha geneldir ve kişinin karakterine/kişiliğine yönelik olumsuz sözleri de içerir. Kötü yanı sık kullanıldığında daha ölümcül olan diğer atlıyı oyuna katması olur.",
        text6: "Örnek: “ Sadece kendinden bahsediyorsun çok bencilsin “.. yerine",
        text7: "“Bu akşamki konuşmamızda ihmal edildiğimi düşünüyorum, benim günümü nasıl geçirdiğimle ilgili de konuşabilirmiyiz?“",
        text8: "Suçlamanın panzehiri ise davranışa odaklanmak kişiye değil. Yargılamadan durumu tanımlamak ve suçlamanın altında yatan ihtiyacı görmeye çalışmak önemli.",
        text9: "Hor görme: Hor görme iğneleme, isim yakıştırma, küçümseme ile alay ve aşağılama içerir. Dört atlının en kötüsü olan hor görme tiksinmeyi ima ettiği için ilişkiyi zehirler. Küçük düşürücü yorumlar ve sözler ilişkideki atmosferin kirlenmesine neden olur.",
        text10: "Örnek:”Bence en iyi becerdiğin iş hiç birşey yapmadan yayılmak” yerine",
        text11: "“Eve gelip hiçbirşey yapmadan durman beni çok rahatsız ediyor, sofra hala toplanmadı.”",
        text12: "Küçümseme ve hor görmeyi ilişkiye getiren kişiye bunu bırakmasını hatırlatmak gerekir. Eğer kendiniz bu davranışı gösteriyorsanız herkesin doğuştan saygıyı hak ettiğini düşünün ve aşağılamanın ilişkinize yıpratıcı etkileri olduğunu hatırlayın. Öyle ki hor görülen insanların bulaşıcı hastalıklara yakalanma oranı diğer insanlarınkinden daha yüksek.",
        text13: "Savunma: Eleştirildiğinde savunmaya geçmek her ne kadar anlaşılabilir birşey olsa da savunmanın bir faydası yok. Çünkü aslında savunma bir çeşit suçlamadır. Benim suçum değil senin suçun demektir ve çatışmayı tırmandırır. Suçlamanın çözümü ise şikayet edenin %2 haklılık payı olduğunu düşünerek dinlemektir. Sen dili yerine ben dili kullanmak, kendi duygularımızın farkına vararak sorumluluğunu almak olumluluğu arttırır.",
        text14: "Örnek: “Geç kalmamızın sebebi sensin ben değilim” yerine",
        text15: "“Zamana dikkat etmeliydim, bu benimde sorumluluğum.”",
        text16: "Duvar örme: Tartışmaların giderek arttara hor görme ve kendini savunmaya yol açtığı ilişkilerde taraflardan biri letişimi keser, bu dördüncü atlının habercisidir. Kişi duvar örerek kendini iletişime kapatır ve ne düşündüğünü söylemez. Özellikle diğer 3 atlının koşuşturduğu ortamlarda takım üyeleri Duvar örerek durumdan kurtulmak isterler ancak bu durum da suçlama ve hor görmeyi arttırır. Duvra örme daha çok erkeklerin tercih ettiği bir yöntem.",
        text17: "Duvar örmek ve kapanmak yerine tartışmaya ara vererek mola almak en doğru yöntem, bu kişinin kendini sakinleştirmesine ve tartışmaya daha pozitif yerden başlamaya yardımcı olur.",
        text18: "Peki bunun çaresi var mı?",
        text19: "Her tülü ilişkide çatışmanın olması aslında olağan. Önemli olan çatışma ortaya çıktığında ne yaptığınız. Gottman’a göre bu davranışlar genellikle kişiler kendini çaresiz ve güçsüz hissettiğinde yada korkunun olduğu ortamlarda ortaya çıkıyor. Ancak korkmaya gerek yok. Gottman panzehirini de bizimle paylaşıyor.",
        text20: "• Takımlarda iletişimde olumluluğu arttırmak için kişileri mahşerin 4 atlısı hakkında eğitmek çözümün ilk adımı. Böylece takım üyelerinden biri 4 atlıdan birini ilişkiye getirerek toksik iletişim kurduğunda diğer takım üyelerinin bunu isimlendirmesi farkındalığı arttırarak tırmanmayı azaltabilir.",
        text21: "• Gottman’a göre bir ilişkide eğer yukarıdaki toksik davranışlardan biri ortaya çıkarsa ilişkideki negatifliği kırmak için en az 5 olumlu şey söylemek gerekiyor. Yani saygı ve takdir ilişkinin 4 atlı tarafından ezilmesini engelliyor. Dikkatlice bakınca herkesin taktir ettiğiniz bir yönünü bulacağınızdan eminim.",
        text22: "• Eğer eşinizle ilişkinizde yada takım içerisinde rahatsızlık duyduğunuz bir durum varsa şikayetinizi isteğe çevierek dile getirin.. ( X oldu, kendimi Y hissettim, Z olsun istiyorum gibi)",
        text23: "• Varsayımlarınızı farkedin ve onlardan vazgeçin. İyi birer dinleyici olun.",
        text24: "• Kendinizi sakinleştirin gerekirse tartışmaya ara verin.",
        text25: "Artık çatışma yasadığınız ilişkilerinize mahşerin 4 atlısından hangisinin koşarak geldiğini ve ilişkinize olan etkisini farkedeceksiniz. İlişkinizde kıyametin kopup kopmaması sizin elinizde:)",
        text26: "Olumlu ve verimli bireysel ve takım ilişkileri kurmanız ve sürdürmeniz dileği ile.."
    }
];

class Mahser extends Component {
    componentDidMount() {
        const text = `Mahser: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="PageItemHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <p>{lItem.text1}</p>
                    <img
                        src={MahserImg}
                        alt={lItem.header}
                        width="40%"
                        height="auto"
                        align="left"
                        hspace="20"
                        vspace="5"
                    />
                    <p>{lItem.text2}</p>
                    <p>{lItem.text3}</p>
                    <p>{lItem.text4}</p>
                    <p>{lItem.text5}</p>
                    <p>{lItem.text6}</p>
                    <p>{lItem.text7}</p>
                    <p>{lItem.text8}</p>
                    <p>{lItem.text9}</p>
                    <p>{lItem.text10}</p>
                    <p>{lItem.text11}</p>
                    <p>{lItem.text12}</p>
                    <p>{lItem.text13}</p>
                    <p>{lItem.text14}</p>
                    <p>{lItem.text15}</p>
                    <p>{lItem.text16}</p>
                    <p>{lItem.text17}</p>
                    <p>{lItem.text18}</p>
                    <p>{lItem.text19}</p>
                    <p>{lItem.text20}</p>
                    <p>{lItem.text21}</p>
                    <p>{lItem.text22}</p>
                    <p>{lItem.text23}</p>
                    <p>{lItem.text24}</p>
                    <p>{lItem.text25}</p>
                    <p>{lItem.text26}</p>
                    <p>Vicdan Merter CPCC, PCC, ACTC</p>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Mahser);