import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { Link } from 'react-router-dom';

const lList = [
    {
        id: "EN",
        header: "Contact Form",
        text1: "Name",
        text2: "Enter name",
        text3: "Surname",
        text4: "Enter surname",
        text5: "Phone",
        text6: "Enter phone number",
        text7: "E-Mail",
        text8: "Enter E-Mail",
        text9: "Please select a topic and write your message",
        text10: "Subject",
        text11: "Choose a subject",
        text12: "Individual Coaching",
        text13: "Mentor Coaching",
        text14: "Team Coaching",
        text15: "Expat Coaching",
        text16: "Training Services",
        text17: "HR Consulting",
        text18: "Other",
        text19: "Message",
        text20: "Enter message",
        text21: "Send",
        text22: "Please fill in all the fields in the form"
    },
    {
        id: "TR",
        header: "İletişim Formu",
        text1: "Ad",
        text2: "Adınızı giriniz",
        text3: "Soyad",
        text4: "Soyadınızı giriniz",
        text5: "Telefon",
        text6: "Telefon numaranızı giriniz",
        text7: "E-Posta",
        text8: "E-Posta adresinizi giriniz",
        text9: "Lütfen ilgilendiğiniz konuyu seçip mesajınızı yazınız",
        text10: "Konu",
        text11: "Konu seçiniz",
        text12: "Bireysel Koçluk",
        text13: "Mentor Koçluğu",
        text14: "Takım Koçluğu",
        text15: "Expat Koçluğu",
        text16: "Eğitim Hizmetleri",
        text17: "IK Danışmanlığı",
        text18: "Diğer",
        text19: "Mesaj",
        text20: "Mesajınızı giriniz",
        text21: "Gönder",
        text22: "Lütfen formun tüm alanlarını doldurunuz"
    }
];

class ContactForm extends Component {
    state = {
        konu: '',
        ad: '',
        soyad: '',
        mobiltelefon: '',
        email: '',
        mesaj: ''
    }

    componentDidUpdate = () => {
        if (this.props.contactFormSent) {
            this.props.changeContactFormSent(false);
            this.setState({
                konu: '',
                ad: '',
                soyad: '',
                phone: '',
                email: '',
                mesaj: ''
            });
        }
    }
    
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    checkValidity = () => {
        let { ad, soyad, mobiltelefon, email, konu, mesaj } = this.state;

        if ((ad !== '' || soyad !== '') &&
            (mobiltelefon !== '' || email !== '') &&
            (konu !== '' || mesaj !== ''))
            return true;

        return false;
    }

    contactSend = () => {
        let { ad, soyad, mobiltelefon, email, konu, mesaj } = this.state;

        let body = mesaj + "%0d%0a%0d%0a" + ad + " " + soyad + 
                "%0d%0aTelefon: " + mobiltelefon + "%0d%0aE-Posta: " + email + "%0d%0a";
        window.location.href = `mailto:vicdan.merter@coachathlon.com?subject=${konu}&body=${body}`;
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div className="ContactForm">
                <div className="ContactHeader">
                    {lItem.header}
                </div>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '80px' }}>{lItem.text1}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="ad"
                        value={this.state.ad}
                        onChange={this.handleChange}
                        placeholder={lItem.text2}
                    />
                </InputGroup>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '80px' }}>{lItem.text3}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="soyad"
                        value={this.state.soyad}
                        onChange={this.handleChange}
                        placeholder={lItem.text4}
                    />
                </InputGroup>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '80px' }}>{lItem.text5}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="mobiltelefon"
                        value={this.state.mobiltelefon}
                        onChange={this.handleChange}
                        placeholder={lItem.text6}
                    />
                </InputGroup>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '80px' }}>{lItem.text7}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder={lItem.text8}
                    />
                </InputGroup>
                <div className="ContactText">
                    {lItem.text9}
                </div>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '80px' }}>{lItem.text10}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        as="select"
                        value={this.state.konu}
                        onChange={this.handleChange}
                        name='konu'
                    >
                        <option key='XX' value={lItem.text11}>{lItem.text11}</option>
                        <option key='BK' value={lItem.text12}>{lItem.text12}</option>
                        <option key='MK' value={lItem.text13}>{lItem.text13}</option>
                        <option key='TK' value={lItem.text14}>{lItem.text14}</option>
                        <option key='EK' value={lItem.text15}>{lItem.text15}</option>
                        <option key='EH' value={lItem.text16}>{lItem.text16}</option>
                        <option key='IK' value={lItem.text17}>{lItem.text17}</option>
                        <option key='DD' value={lItem.text18}>{lItem.text18}</option>
                    </FormControl>
                </InputGroup>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '80px' }}>{lItem.text19}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="mesaj"
                        as="textarea"
                        rows={5}
                        value={this.state.mesaj}
                        onChange={this.handleChange}
                        placeholder={lItem.text20}
                    />
                </InputGroup>
                <div className="ContactButton">
                {
                    this.checkValidity() ? 
                    <Link className="MyButton btn-secondary"
                    to={`/iletisim-formu-alindi?ileten=Z&ad=${this.state.ad}&soyad=${this.state.soyad}&tel=${this.state.mobiltelefon}&email=${this.state.email}&konu=${this.state.konu}&mesaj=${this.state.mesaj}`}
                >
                    {lItem.text21}
                </Link> :
                    <Link
                        className="MyButton btn-secondary"
                        to=""
                    >
                        {lItem.text22}
                    </Link>
                }
                </div>
            </div>
        )        
    }
}

export default ContactForm;