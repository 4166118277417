import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { invokeLogger, logout } from './actions/account';

import calogo from './images/ca-logo335.png';

const lList = [
    {
        id: "EN",
        language: "EN",
        text1: "Home",
        text2: "Bio",
        text3: "Articles",
        text4: "Calendar",
        text5: "My-profile",
        text6: "SignOut",
        text7: "SignIn",
        text8: "WheelOfLife"
    },
    {
        id: "TR",
        language: "TR",
        text1: "AnaSayfa",
        text2: "Bio",
        text3: "Yazılar",
        text4: "Takvim",
        text5: "Profilim",
        text6: "Çıkış",
        text7: "Giriş",
        text8: "YaşamTekerleği"
    }
];

class Menu extends Component {
    changeLanguage = (language) => {
        const text = `Menu: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, Language changed from ${this.props.language} to ${language}`;
        this.props.invokeLogger({ id: "w", text });
        localStorage.setItem("userLanguage", language);

        if (this.props.changeLanguage)
            this.props.changeLanguage(language);
    }

    logout = () => {
        const id = this.props.account.id;
        this.props.logout(id);
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div className="Menu">
                <Link to='/'>
                    <div className="MenuLogo">
                        <img src={calogo} width="100%" alt='Coachathlon' />
                    </div>
                </Link>
                
                <div className="MenuContainer">
                    <Link className="MenuItem" to='/'>{lItem.text1}</Link>
                    <Link className="MenuItem" to='/hakkimda'>{lItem.text2}</Link>
                    <Link className="MenuItem" to='/yazilar'>{lItem.text3}</Link>
                    <Link className="MenuItem" to='/wol'>{lItem.text8}</Link>
                    {/*
                        this.props.account.loggedIn ?
                        <span>
                            <Link className="MenuItem" to='/takvim'>{lItem.text4}</Link>
                            <Link className="MenuItem" to='/profilim'>{lItem.text5}</Link>
                            <span className="MenuItem" onClick={this.logout}>{lItem.text6}</span>
                        </span> :
                        <Link className="MenuItem" to='/giris'>{lItem.text7}</Link>
                    */}
                    <span className="MenuItem">
                        <span className="MenuShort">
                        {lItem.language}
                        <div className="MenuShortContent">
                                {
                                    lList.map((item,i) => (
                                        <div className="MenuShortItem" key={i}>
                                            <div
                                                className="MenuSubItemItem"
                                                onClick={() => this.changeLanguage(item.id)}
                                            >
                                                {item.language}
                                            </div>
                                        </div>
                                    ))
                                }
                        </div>
                        </span>
                    </span>
                </div>
            </div>
        )        
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger, logout }
)(Menu);