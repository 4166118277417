import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import MentorImg from '../images/wateringplant.jpg';
import boostImg_TR from '../pdfs/BoostMentorCoaching_TR.png';
import boostImg_EN from '../pdfs/BoostMentorCoaching_EN.png';
import boostPdf_TR from '../pdfs/BoostMentorCoaching_TR.pdf';
import boostPdf_EN from '../pdfs/BoostMentorCoaching_EN.pdf';
//import boostMp4 from '../pdfs/BoostMentorCoaching_TR.mp4';

const lList = [
    {
        id: "EN",
        header: "Mentor Coaching",
        text: [
            "Mentor coaching, which is mandatory for you to become an ICF certified professional coach, contributes to the improvement of your coaching performance by enhancing your ICF Core competencies, increasing your awareness of your strengths and areas of development.",
            "Due to ICF requirements, mentor coaching takes place over a three-month period. This period allows both the mentee to implement the achievements obtained with the mentor coach and to give feedback through the application.",
            "Gains",
            "• Practice and skill development in each core competence",
            "• Learning from observation, coaching and feedback",
            "• Mastering the coaching process and accelerating learning",
            "• Raising awareness about the impact of your coaching",
            "• Prepare for the Coaching Knowledge Assessment exam",
            "• Receive verbal or written feedback on coaching records",
            "• Guidance in the ICF ACC, PCC application process and selection of appropriate session recording",
            "Below you can find the details of our mentor coaching program.",
        ],
        videoError: "Your browser does not support mp4 video play."
    },
    {
        id: "TR",
        header: "Mentor Koçluğu",
        text: [
            "ICF ünvanlı profesyonel koç olma yolunda almanız zorunlu olan mentor koçluğu, ICF Temel yetkinliklerinin pekişmesine, güçlü yönleriniz ve gelişim alanlarınıza dair farkındalığınızı arttırarak koçluk performansınızın gelişmesine katkıda bulunur.",
            "ICF gereklilikleri dolayısı ile mentor koçluğu üç aylık bir sürede gerçekleştirilir. Bu süre hem mentor koçla elde edilen kazanımların menti tarafından uygulanmasına hem de uygulama üzerinden geribildirim verilmesine izin verir.",
            "Kazanımlar",
            "• Her bir temel yetkinlikte uygulama ve beceri gelişimi",
            "• Gözlem, koçluk ve geribildirimden öğrenmek",
            "• Koçluk sürecinde ustalaşmak ve öğrenmeyi hızlandırmak",
            "• Koçluğunuzun etkisi hakkında farkındalık yaratmak",
            "• Koçluk Bilgi Değerlendirme sınavına hazırlamak",
            "• Koçluk kayıtları üzerinden sözlü veya yazılı geri bildirim almak",
            "• ICF ACC, PCC başvuru sürecinde rehberlik ve uygun seans kaydı seçimi",
            "Aşağıda mentor koçluğu programımızın detaylarını bulabilirsiniz.",
        ],
        videoError: "Tarayıcınız mp4 video oynatmayı desteklemiyor."
    }
];

class Mentor extends Component {
    componentDidMount() {
        const text = `Mentor: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    handleClick = (pg) => {
        let url = '';
        switch(pg) {
            case 'boostPdf':
                switch(this.props.language) {
                    case 'EN':
                        url = boostPdf_EN;
                        break;
                    case 'TR':
                        url = boostPdf_TR;
                        break;
                    default:
                        return (0);
                }
                break;
            /*
            case 'boostMp4':
                url = boostMp4;
                break;
            */
            default:
                return (0);
        }
        const win = window.open(url, '_blank');
        if (win != null) {
              win.focus();
        }
        return (1);
    }
    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="PageItemHeader">
                        {lItem.header}
                </div>
                <div className="PageText">
                    <img
                            src={MentorImg}
                            alt={lItem.header}
                            width="40%"
                            height="auto"
                            align="left"
                            hspace="20"
                            vspace="5"
                        />
                    <div>
                        {
                            lItem.text.map((text,i) => (
                                <p>{text}</p>
                            ))
                        }
                    </div>
                    <img className="FooterLink" src={lItem.language === 'TR' ? boostImg_TR : boostImg_EN} height="240px" alt='Boost Mentor Coaching' onClick={() => this.handleClick('boostPdf')}/>
                    {/*
                    <video src={boostMp4} type="video/mp4" height="240" controls>
                        {lItem.videoError}
                    </video>
                    */}
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Mentor);