import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import TakimImg from '../images/leaves.jpg';

const lList = [
    {
        id: "EN",
        header: "Team Coaching",
        text1: "We believe in the impact of team coaching and we love team coaching!",
        text2: "While individual change can be powerful, helping a group of people come together to create a shared culture of success is incredibly exciting.",
        text3: "Of course we understand the difficulties. Most senior management teams are under severe pressure to keep up with change in the VUCA world and achieve corporate goals. This is exactly why Coachathlon takes the team into a transformative process by treating the team as a system rather than as a group of individuals. It reveals the system's own voice, values and expectations of the existing system in teams.",
        text4: "It transforms the team culture from “ME” to “WE” by concentrating on role-to-role communication and relationship, human-to-human relationship, and strengthens cooperation. Increases productivity and positivity in the team.",
        text5: "Is your company's senior management team really a team or are they just people who come together due to their positions? We believe in the impact of team coaching to increase productivity and positivity in teams."
    },
    {
        id: "TR",
        header: "Takım Koçluğu",
        text1: "Takım koçluğunun etkisine inanıyor ve takım koçluğunu seviyoruz!",
        text2: "Bireysel değişim elde etmek güçlü olsa da, bir grup insanın ortak bir başarı kültürü oluşturmak için bir araya gelmesine yardım etmek olağanüstü heyecan verici.",
        text3: "Elbette zorlukları anlıyoruz. Çoğu üst yönetim ekibi, VUCA- dünyada değişime ayak uydurabilmek ve kurumsal hedeflere ulaşmak için ciddi baskı altında. İşte tamda bu sebeple Coachathlon, takımı bireylerden oluşan bir gurup yerine, bir sistem olarak ele alarak dönüştürücü bir sürece taşır. Takımlarda var olan sistemin kendine ait sesini, değerlerini ve beklentilerini ortaya çıkarır.",
        text4: "Rolden role iletişim ve ilişkiden, insan insana ilişkiye yoğunlaşarak takım kültürünü “BEN”den “BİZ”e dönüştürür, işbirliğini güçlendirir. Takımdaki verimliliği ve olumluluğu arttırır.",
        text5: "Şirketinizin üst yönetim ekibi, gerçekten bir takım mı, yoksa sadece pozisyonları gereği biraraya gelmiş kişiler mi? Takımlarda verimlilik ve olumluluğu arttırmak için takım koçluğunun etkisine inanıyoruz."
    }
];

class Takim extends Component {
    componentDidMount() {
        const text = `Takim: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="PageItemHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <p>{lItem.text1}</p>
                    <img
                        src={TakimImg}
                        alt={lItem.header}
                        width="40%"
                        height="auto"
                        align="left"
                        hspace="20"
                        vspace="5"
                    />
                    <p>{lItem.text2}</p>
                    <p>{lItem.text3}</p>
                    <p>{lItem.text4}</p>
                    <p>{lItem.text5}</p>  
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Takim);