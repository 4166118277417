import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import MolaImg from '../images/mola.jpg';

const lList = [
    {
        id: "EN",
        header: "Your brain works differently when you take a break!",
        text1: "You remember the school period, we used to take breaks between classes and run away from all our stress, boredom and inactivity. Why don't we allow ourselves breaks as often in our adult lives? Most of the time, as we rush from one meeting to the next, we will get up and stretch ourselves, take a step or two; we don't get a chance to drink a glass of water or do anything to clear our minds!",
        text2: "In today's remote and hybrid working order, institutions now give a lot of space to wellbeing/wellness programs and encourage self-care.",
        text3: "According to a study commissioned by Microsoft, taking a break while attending virtual meetings has positive effects on the stress level in the brain.",
        text4: "The antidote to overcoming stress and fatigue is simple: taking short breaks. Because when you take a break, your brain works differently!",
        text5: "Let's share a few tips from this research;",
        text6: "Instead of successive meetings; putting tiny breaks between two meetings allows the brain to 'reset', reducing the cumulative stress buildup. To do this, schedule meetings to end 10 minutes before the full time. So instead of 1-hour meetings, organize 50-minute meetings! For example, 14:00-14:50. Successive meetings can weaken your focus and active participation. Do short meditations during these ten-minute breaks. Meditation supports your focus by bringing you into the moment. Try simple but effective methods such as 3-minute breathing meditation, or square breathing. Or if you have a bonus 10 minutes, do whatever you want to do! Rushing from one meeting to the next increases stress. Before you enter a new meeting, take some time to check in with yourself. Release the thoughts, feelings and emotions from the last meeting, close the arc of the ending meeting, enter the meeting with your intention for the new meeting!",
        text7: "For the entire study:"
    },
    {
        id: "TR",
        header: "Mola verdiğinizde beyniniz farklı çalışıyor!",
        text1: "Okul döneminden hatılarsınız, ders aralarında teneffüse çıkar ve bütün stresimizi, can sıkıntımızı, hareketsizliğimizi koşturarak atardık. Yetişkin hayatımızda kendimize neden aynı sıklıkta mola hakkı tanımıyoruz? Çoğu zaman bir toplantıdan diğerine koşturuken kendimize kalkıp gerinecek, bir iki adım atacak; bir bardak su içecek ya da kafamızı boşaltacak birşey yapacak fırsatımız olamıyor!",
        text2: "Günümüzün uzaktan ve hibrit çalışma düzeninde artık kurumlar wellbeing/wellness programlarına oldukça fazla yer veriyorlar ve öz bakımı teşvik ediyorlar.",
        text3: "Microsoft'un yaptırdığı bir araştırmaya göre, sanal toplantılara katılırken ara vermenin beyindeki stress düzeyine olumlu etkileri olduğunu gösteriyor.",
        text4: "Stress ve yorgunluğun üstesinden gelmenin panzehiri basit: kısa molalar vermek.  Zira mola verdiğinizde beyniniz farklı çalışıyor!",
        text5: "Bu araştırmadan birkaç ipucu paylaşalım;",
        text6: "Peşpeşe toplantılar yerine; iki toplantı arasına minik molalar koymak, beynin kümülatif stres birikimini azaltarak 'sıfırlamasına' izin veriyor. Bunun için toplantıları tam saatten 10 dakika önce bitecek şekilde planlayın. Yani 1 saatlik toplantılar yerine 50 dk toplantılar organize edin! Mesela 14:00-14:50 gibi. Peşpeşe toplantılar odaklanma ve aktif katılım gücünüzü azaltabilir. Bu on dakikalık molalarda kısa meditasyonlar yapın. Meditasyon sizi ana getirerek odaklanmanızı destekler. 3 dakikalık nefesi izleme meditasyonu, yada kare nefes gibi  basit ama etkili yöntemleri deneyin. Ya da bonus bir 10 dakikanız olsa ne yapmak isterseniz onu yapın! Bir toplantıdan diğerine koşturmak stresi arttırır. Yeni bir toplantıya grimeden önce kendinizle check-in yapmak için biraz zaman ayırın. Son toplantıdaki düşünceleri, hisleri ve duyguları serbest bırakın, biten toplantının arkını kapatın, yeni toplantı için niyetinizi belirleyerek toplantıya girin!",
        text7: "Araştırmanın tümü için:"
    }
];

class Mola extends Component {
    componentDidMount() {
        const text = `Mola: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    handleClick = (pg) => {
        const win = window.open("https://www.microsoft.com/en-us/worklab/work-trend-index/brain-research", "_blank");
        if (win != null) {
              win.focus();
        }
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="PageItemHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <p>{lItem.text1}</p>
                    <img
                        src={MolaImg}
                        alt={lItem.header}
                        width="40%"
                        height="auto"
                        align="left"
                        hspace="20"
                        vspace="5"
                    />
                    <p>{lItem.text2}</p>
                    <p>{lItem.text3}</p>
                    <p> {lItem.text4}</p>              
                    <p>{lItem.text5}</p>
                    <p>{lItem.text6}</p>
                    <p>{lItem.text7}</p>
                    <p className="PageLink" onClick={this.handleClick}>
                    https://www.microsoft.com/en-us/worklab/work-trend-index/brain-research
                    </p>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Mola);