import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import EgitimImg from '../images/plant.jpg';

const lList = [
    {
        id: "EN",
        header: "Training Services",
        text1: "We design programs that will create behavior and attitude change based on adult learning and active participation, focusing on raising individual awareness in the learning environment created by sharing coaching and facilitation skills and the participants' own experiences, perspectives and questions.",
        text2: "When standard programs do not meet the needs of your institution, our training topics that we specially designed for the institution to develop the competencies and behaviors you need:",
        text3: "➢ Coaching Skills for Managers",
        text4: "➢ Coaching Skills for HR Partners",
        text5: "➢ Basic Leadership Skills",
        text6: "➢ Performance Management",
        text7: "➢ Feedback Skills",
        text8: "➢ Communication and Relationship Management",
        text9: "➢ Productivity and Time Management",
        text10: "➢ Growth Mindset",
        text11: "➢ Change Management",
        text12: "➢ Values Training"
    },
    {
        id: "TR",
        header: "Eğitim Hizmetleri",
        text1: "Yetişkin öğrenmesine ve aktif katılıma dayalı, koçluk ve fasilitasyon becerileri ile katılımcıların kendi deneyim, bakış açıları ve sorularını paylaşarak yaratılan öğrenme ortamında bireysel farkındalık yaratmaya odaklanarak davranış ve tutum değişikliği oluşturacak programlar tasarlıyoruz.",
        text2: "Standart programlar kurumunuzun ihtiyaçlarını karşılamadığında, özellikle ihtiyaç duyduğunuz yetkinlik ve davranışları geliştirmek için kuruma özel tasarladığımız eğitim konularımız:",
        text3: "➢ Yöneticiler için Koçluk Becerileri",
        text4: "➢ İK İş Ortakları için Koçluk Becerileri",
        text5: "➢ Temel Liderlik Becerileri",
        text6: "➢ Performans Yönetimi",
        text7: "➢ Geribildirim Becerileri",
        text8: "➢ İletişim ve İlişki Yönetimi",
        text9: "➢ Verimlilik ve Zaman Yönetimi",
        text10: "➢ Gelişim Zihniyeti",
        text11: "➢ Değişim Yönetimi",
        text12: "➢ Değerler Eğitimi"
    }
];

class Egitim extends Component {
    componentDidMount() {
        const text = `Egitim: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <div className="PageItemHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <img
                        src={EgitimImg}
                        alt="Eğitim Hizmetleri"
                        width="40%"
                        height="auto"
                        align="left"
                        hspace="20"
                        vspace="5"
                    />
                    <p>{lItem.text1}</p>
                    <p>{lItem.text2}</p>
                    <p>{lItem.text3}</p>
                    <p>{lItem.text4}</p>
                    <p>{lItem.text5}</p>
                    <p>{lItem.text6}</p>
                    <p>{lItem.text7}</p>
                    <p>{lItem.text8}</p>
                    <p>{lItem.text9}</p>
                    <p>{lItem.text10}</p>
                    <p>{lItem.text11}</p>
                    <p>{lItem.text12}</p>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Egitim);