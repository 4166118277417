import { ACCOUNT } from '../actions/types';
import fetchStates from './fetchStates';

const DEFAULT_ACCOUNT = {
    loggedIn: false,
    visitor: {
        serialNumber: "",
        dateOfFirstVisit: "",
        accountId: "",
        userName: "",
        userSurname: "",
        userPhone: "",
        userEmail: "",
        userLanguage: ""
    }
};

function verifyStatus(status) {
    return (
        status === 'FM' || // Abone olmuş üye
        status === 'AK' || // Abone bilgilerini güncellemiş deneme süresindeki üye
        status === 'AD' || // Administrator
        status === 'I1' || // Mail adresini doğrulamış üye
        status === 'I0'    // Mail adresini doğrulaMAmış üye 
    );
}

const account = (state = DEFAULT_ACCOUNT, action) => {
    switch(action.type) {
        case ACCOUNT.FETCH:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.FETCH_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message,
                statusCode: action.statusCode
            };
        case ACCOUNT.FETCH_SUCCESS:
            return {
                ...state,
                id: action.account.id,
                startDate: action.account.startDate,
                accountStatus: action.account.accountStatus,
                ad: action.account.ad,
                soyad: action.account.soyad,
                email: action.account.email,
                mobiltelefon: action.account.mobiltelefon,
                haberler: action.account.haberler,
                sozlesme: action.account.sozlesme,
                status: fetchStates.success,
                message: action.message,
                loggedIn: verifyStatus(action.account.accountStatus)
            };
        case ACCOUNT.FETCH_LOGOUT_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                message: action.message,
                loggedIn: false
            };
        case ACCOUNT.FETCH_AUTHENTICATED_SUCCESS:
            return {
                ...state,
                id: action.account.id,
                startDate: action.account.startDate,
                accountStatus: action.account.accountStatus,
                ad: action.account.ad,
                soyad: action.account.soyad,
                email: action.account.email,
                mobiltelefon: action.account.mobiltelefon,
                haberler: action.account.haberler,
                sozlesme: action.account.sozlesme,
                status: fetchStates.success,
                message: action.message,
                loggedIn: action.authenticated
            };
        case ACCOUNT.UPDATE:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.UPDATE_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.UPDATE_SUCCESS:
            return {
                ...state,
                ad: action.account.ad,
                soyad: action.account.soyad,
                mobiltelefon: action.account.mobiltelefon,
                accountStatus: action.account.accountStatus,
                haberler: action.account.haberler,
                sozlesme: action.account.sozlesme,
                status: fetchStates.success,
                message: action.message,
                loggedIn: true
            };
        case ACCOUNT.UPDATEABONE:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.UPDATEABONE_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.UPDATEABONE_SUCCESS:
            return {
                ...state,
                ad: action.account.ad,
                soyad: action.account.soyad,
                accountStatus: action.account.accountStatus,
                telefon: action.account.telefon,
                adres: action.account.adres,
                postaKodu: action.account.postaKodu,
                ilce: action.account.ilce,
                sehir: action.account.sehir,
                status: fetchStates.success,
                message: action.message
            };
        case ACCOUNT.ACTIVATE:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.ACTIVATE_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.ACTIVATE_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                message: action.message,
                activate: true
            };
        case ACCOUNT.UPDATEDISCLAIMER:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.UPDATEDISCLAIMER_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.UPDATEDISCLAIMER_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                sozlesme: action.sozlesme
            };
        case ACCOUNT.FORGOTTENPASSWORD:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.FORGOTTENPASSWORD_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.FORGOTTENPASSWORD_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                message: action.message,
                activate: true
            };
        case ACCOUNT.INVOKELOGGER:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.INVOKELOGGER_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.INVOKELOGGER_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                message: action.message
            };
        case ACCOUNT.WINDOWCHECK:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.WINDOWCHECK_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.WINDOWCHECK_SUCCESS:
            let sN = localStorage.getItem("serialNumber");
            if (sN === undefined || sN === null ) {
                localStorage.setItem("serialNumber", action.visitor.serialNumber);
                localStorage.setItem("dateOfFirstVisit", action.visitor.dateOfFirstVisit);
                localStorage.setItem("accountId", action.visitor.accountId);
            }
            return {
                ...state,
                status: fetchStates.success,
                visitor: action.visitor
            };
        default:
            return state;
    }
};

export default account;