import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';

import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import InputGroup from 'react-bootstrap/InputGroup';

import ReactToPrint, { PrintContextConsumer } from 'react-to-print';


import WoL from './images/wol_white.jpg';

import WoLCell1_1 from './images/wol_blue_1.png';
import WoLCell1_2 from './images/wol_blue_2.png';
import WoLCell1_3 from './images/wol_blue_3.png';
import WoLCell1_4 from './images/wol_blue_4.png';
import WoLCell1_5 from './images/wol_blue_5.png';
import WoLCell1_6 from './images/wol_blue_6.png';
import WoLCell1_7 from './images/wol_blue_7.png';
import WoLCell1_8 from './images/wol_blue_8.png';
import WoLCell1_9 from './images/wol_blue_9.png';
import WoLCell1_10 from './images/wol_blue_10.png';

import WoLCell2_1 from './images/wol_orange_1.png';
import WoLCell2_2 from './images/wol_orange_2.png';
import WoLCell2_3 from './images/wol_orange_3.png';
import WoLCell2_4 from './images/wol_orange_4.png';
import WoLCell2_5 from './images/wol_orange_5.png';
import WoLCell2_6 from './images/wol_orange_6.png';
import WoLCell2_7 from './images/wol_orange_7.png';
import WoLCell2_8 from './images/wol_orange_8.png';
import WoLCell2_9 from './images/wol_orange_9.png';
import WoLCell2_10 from './images/wol_orange_10.png';

import WoLCell3_1 from './images/wol_cyan_1.png';
import WoLCell3_2 from './images/wol_cyan_2.png';
import WoLCell3_3 from './images/wol_cyan_3.png';
import WoLCell3_4 from './images/wol_cyan_4.png';
import WoLCell3_5 from './images/wol_cyan_5.png';
import WoLCell3_6 from './images/wol_cyan_6.png';
import WoLCell3_7 from './images/wol_cyan_7.png';
import WoLCell3_8 from './images/wol_cyan_8.png';
import WoLCell3_9 from './images/wol_cyan_9.png';
import WoLCell3_10 from './images/wol_cyan_10.png';

import WoLCell4_1 from './images/wol_pink_1.png';
import WoLCell4_2 from './images/wol_pink_2.png';
import WoLCell4_3 from './images/wol_pink_3.png';
import WoLCell4_4 from './images/wol_pink_4.png';
import WoLCell4_5 from './images/wol_pink_5.png';
import WoLCell4_6 from './images/wol_pink_6.png';
import WoLCell4_7 from './images/wol_pink_7.png';
import WoLCell4_8 from './images/wol_pink_8.png';
import WoLCell4_9 from './images/wol_pink_9.png';
import WoLCell4_10 from './images/wol_pink_10.png';

import WoLCell5_1 from './images/wol_pastelgreen_1.png';
import WoLCell5_2 from './images/wol_pastelgreen_2.png';
import WoLCell5_3 from './images/wol_pastelgreen_3.png';
import WoLCell5_4 from './images/wol_pastelgreen_4.png';
import WoLCell5_5 from './images/wol_pastelgreen_5.png';
import WoLCell5_6 from './images/wol_pastelgreen_6.png';
import WoLCell5_7 from './images/wol_pastelgreen_7.png';
import WoLCell5_8 from './images/wol_pastelgreen_8.png';
import WoLCell5_9 from './images/wol_pastelgreen_9.png';
import WoLCell5_10 from './images/wol_pastelgreen_10.png';

import WoLCell6_1 from './images/wol_yellow_1.png';
import WoLCell6_2 from './images/wol_yellow_2.png';
import WoLCell6_3 from './images/wol_yellow_3.png';
import WoLCell6_4 from './images/wol_yellow_4.png';
import WoLCell6_5 from './images/wol_yellow_5.png';
import WoLCell6_6 from './images/wol_yellow_6.png';
import WoLCell6_7 from './images/wol_yellow_7.png';
import WoLCell6_8 from './images/wol_yellow_8.png';
import WoLCell6_9 from './images/wol_yellow_9.png';
import WoLCell6_10 from './images/wol_yellow_10.png';

import WoLCell7_1 from './images/wol_green_1.png';
import WoLCell7_2 from './images/wol_green_2.png';
import WoLCell7_3 from './images/wol_green_3.png';
import WoLCell7_4 from './images/wol_green_4.png';
import WoLCell7_5 from './images/wol_green_5.png';
import WoLCell7_6 from './images/wol_green_6.png';
import WoLCell7_7 from './images/wol_green_7.png';
import WoLCell7_8 from './images/wol_green_8.png';
import WoLCell7_9 from './images/wol_green_9.png';
import WoLCell7_10 from './images/wol_green_10.png';

import WoLCell8_1 from './images/wol_pastelpink_1.png';
import WoLCell8_2 from './images/wol_pastelpink_2.png';
import WoLCell8_3 from './images/wol_pastelpink_3.png';
import WoLCell8_4 from './images/wol_pastelpink_4.png';
import WoLCell8_5 from './images/wol_pastelpink_5.png';
import WoLCell8_6 from './images/wol_pastelpink_6.png';
import WoLCell8_7 from './images/wol_pastelpink_7.png';
import WoLCell8_8 from './images/wol_pastelpink_8.png';
import WoLCell8_9 from './images/wol_pastelpink_9.png';
import WoLCell8_10 from './images/wol_pastelpink_10.png';

const lList = [
    {
        id: "EN",
        header: "Wheel of Life",
        text: [ "Career",
            "Money",
            "Health",
            "Friends & Family",
            "Significant Other / Romance",
            "Personal Growth",
            "Fun & Creation",
            "Physical Environment"
        ],
        yazdir: "Print",
        text1: "The life wheel is a very common tool in coaching and a very effective tool that invites you to look at important areas in one's life (eg career, relationships, health, family, education, etc.).",
        text2: "During the coaching process, the life wheel can help a person identify their life goals and plan the steps needed to reach those goals. By considering each area on the wheel of life separately, the person can determine which areas need further improvement and focus on how to make progress in these areas.",
        text3: "The wheel of life is used to determine how satisfied or successful the person is in these areas. Each area is represented as a slice in the wheel, and the person evaluates himself for each area.",
        text4: "This study measures the current level of satisfaction in each area. You can think of it as a snapshot of your life experiences. You can do this work yourself as follows.",
        text5: "Assess the center of the wheel as “0” and the outside as “10”, rate each area from “0 to 10”. This new circumference of the circle represents your Wheel of Life.",
        text6: "•	How satisfied are you with your entire life?",
        text7: "•	Which areas contribute the most to your satisfaction?",
        text8: "•	What are the barriers?",
        text9: "•	How bumpy would your ride be if this were a real wheel?",
        text10: "•	What will you do for balance?",
    },
    {
        id: "TR",
        header: "Yaşam Tekerleği",
        text: [ "Kariyer",
            "Para",
            "Sağlık",
            "Aile & Arkadaşlar",
            "Değerli Kişi / Romans",
            "Kişisel Gelişim",
            "Eğlence & Dinlence",
            "Fiziksel Çevre"
        ],
        yazdir: "Yazdır",
        text1: "Yaşam tekerleği, koçlukta çok sık kullanılan bir araçtır ve kişinin yaşamındaki önemli alanları (örneğin kariyer, ilişkiler, sağlık, aile, eğitim vb.) bakmaya davet eden çok etkili bir araçtır.",
        text2: "Yaşam tekerleği, koçluk sürecinde kişinin yaşamındaki hedeflerini belirlemesine ve bu hedeflere ulaşmak için gereken adımları planlamasına yardımcı olabilir. Kişi, yaşam tekerleğindeki her bir alanı ayrı ayrı ele alarak, hangi alanlarda daha fazla gelişim göstermesi gerektiğini belirleyebilir ve bu alanlarda nasıl ilerleme kaydedebileceğine odaklanabilir.",
        text3: "Yaşam tekerleği, kişinin bu alanlarda ne kadar tatmin olduğunu veya ne kadar başarılı olduğunu belirlemek için kullanılır. Her bir alan, tekerlekteki bir dilim olarak temsil edilir ve kişi, her alan için kendisini değerlendirir.",
        text4: "Bu çalışma herbir alanda o günkü tatmin düzeyini ölçer.  Yaşam deneyimlerinizin o anki fotoğrafı gibi düşünebilirsiniz. Bu çalışmayı şu şekilde kendiniz yapabilirsiniz.",
        text5: "Tekerleğin merkezini “0” ve dışını “10” kabul ederek herbir alanı “0’dan 10’a” değerlendirin. Çemberin oluşan bu yeni çevresi sizin Yaşam Tekerleğinizi temsil eder.",
        text6: "•	Tüm yaşamınızla ilgili olarak ne kadar tatminsiniz?",
        text7: "•	Tatmininize en fazla katkı yapan alanlar hangileridir?",
        text8: "•	Engel olanlar hangi alanlardır?",
        text9: "•	Bu gerçek bir tekerlek olsaydı gezintiniz ne kadar sarsıntılı olurdu?",
        text10: "•	Denge için ne yapacaksınız?",
    }
];

class WoLife extends Component {
    state = {
        WoLCell: [
            [ null, WoLCell1_1, WoLCell1_2, WoLCell1_3, WoLCell1_4, WoLCell1_5, WoLCell1_6, WoLCell1_7, WoLCell1_8, WoLCell1_9, WoLCell1_10 ],
            [ null, WoLCell2_1, WoLCell2_2, WoLCell2_3, WoLCell2_4, WoLCell2_5, WoLCell2_6, WoLCell2_7, WoLCell2_8, WoLCell2_9, WoLCell2_10 ],
            [ null, WoLCell3_1, WoLCell3_2, WoLCell3_3, WoLCell3_4, WoLCell3_5, WoLCell3_6, WoLCell3_7, WoLCell3_8, WoLCell3_9, WoLCell3_10 ],
            [ null, WoLCell4_1, WoLCell4_2, WoLCell4_3, WoLCell4_4, WoLCell4_5, WoLCell4_6, WoLCell4_7, WoLCell4_8, WoLCell4_9, WoLCell4_10 ],
            [ null, WoLCell5_1, WoLCell5_2, WoLCell5_3, WoLCell5_4, WoLCell5_5, WoLCell5_6, WoLCell5_7, WoLCell5_8, WoLCell5_9, WoLCell5_10 ],
            [ null, WoLCell6_1, WoLCell6_2, WoLCell6_3, WoLCell6_4, WoLCell6_5, WoLCell6_6, WoLCell6_7, WoLCell6_8, WoLCell6_9, WoLCell6_10 ],
            [ null, WoLCell7_1, WoLCell7_2, WoLCell7_3, WoLCell7_4, WoLCell7_5, WoLCell7_6, WoLCell7_7, WoLCell7_8, WoLCell7_9, WoLCell7_10 ],
            [ null, WoLCell8_1, WoLCell8_2, WoLCell8_3, WoLCell8_4, WoLCell8_5, WoLCell8_6, WoLCell8_7, WoLCell8_8, WoLCell8_9, WoLCell8_10 ]
        ],
        inputCell: [ "0", "0", "0", "0", "0", "0", "0", "0" ]
    }

    componentDidMount() {
        const text = `WOL: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    changePrinting = (printing) => {
        this.setState({ printing })
    }

    handleButtonChange = (value, i) => {
        const newCell = [...this.state.inputCell];

        newCell[i] = value;
        this.setState({ inputCell: newCell });
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        const cellArray = this.state.inputCell;
        const answers = this.state.WoLCell[0];
        return (
            <div className="Page WoL">
                <div className="PageHeader">
                    {lItem.header}
                </div>
                <div className="WoLifePage">
                {
                    cellArray.map((cell, i) => {
                        return (<div key={i}>
                        {
                            <InputGroup>
                                <span className="WoLifeInputText">{lItem.text[i]}</span>
                                <ToggleButtonGroup
                                    type="radio"
                                    name={`soru${i}`}
                                    defaultValue={cell}
                                    onChange={(value) => this.handleButtonChange(value, i)}
                                >
                                    {
                                        answers.map((cell,i) => {
                                            return <ToggleButton
                                                        key={i}
                                                        variant={'outline-secondary'}
                                                        value={i}
                                                        size="sm"
                                                    >
                                                        {i++}
                                                    </ToggleButton>
                                        })
                                    }
                                </ToggleButtonGroup>
                            </InputGroup>
                        }
                        </div>)
                    })
                }
                </div>
                <div className="WoLifeContainer">
                    <div className="WoLife">
                        <img src={WoL} alt={lItem.header} />
                        {
                        cellArray.map((cell, i) => {
                            return <div key={i} className="WoLife_f">
                                {
                                    (+cell <= 0 || cell === "" ||  +cell > 10) ? null :
                                    <img src={this.state.WoLCell[i][cell]} alt={lItem.header} width="325px" />
                                }
                            </div>
                        })
                        }
                    </div>
                </div>
                <div>
                    <ReactToPrint content={() => this}>
                    <PrintContextConsumer>
                        {({ handlePrint }) => (
                            <Button variant='info' onClick={handlePrint}>
                                {lItem.yazdir}
                            </Button>
                        )}
                    </PrintContextConsumer>
                    </ReactToPrint>
                </div>
                <div className="PageText">
                    <p>{lItem.text1}</p>
                    <p>{lItem.text2}</p>
                    <p>{lItem.text3}</p>
                    <p>{lItem.text4}</p>
                    <p>{lItem.text5}</p>
                    <p>{lItem.text6}</p>
                    <p>{lItem.text7}</p>
                    <p>{lItem.text8}</p>
                    <p>{lItem.text9}</p>
                    <p>{lItem.text10}</p>
                </div>
            </div>
        )        
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(WoLife);