import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import DortAnlasmaImg from '../images/dort.png';

const lList = [
    {
        id: "EN",
        header: "The Four Agreements",
        text1: "Don Miguel Ruiz, in his book The Four Agreements, summarizes the Toltec wisdom brought to life by the Mexican Indians under the title of 4 agreements. He suggests that the modern person, who is under intense struggle and stress in today's lifestyle, actually returns to his essence and makes a 4-point agreement with himself.",
        text2: "The Four Agreements is one of the books they recommended to us during my leadership training. If you want to reach your own best self, I recommend you to adapt Ruiz's 4 basic suggestions to your life. This article is actually a brief summary of Ruiz's book. I highly recommend reading the entire book to fully understand the subject. I get a lot of questions about what is important, especially for a career and success in the leadership journey. Although I do not think that there is a magic formula on the way to leadership, I think that leadership is directly related to “personality” and reaching your best self will support your career development.",
        text3: "While we were growing up, we made thousands of agreements with social and family conditioning without realizing it. If we can implement the four new agreements that will replace these agreements, change will begin. If we want to live a positive and happy life, we have to break our fear-based agreements and introduce love-based agreements into our lives. While fear-based agreements are consuming your energy, it is not possible to claim your individual power. If you can make these four love-based agreements, you can claim your individual power.",
        text4: "Applying Ruiz's principles to your life is both difficult and easy. The first deal is easy to understand and apply. The latter is difficult to understand and apply. The third agreement is difficult to understand, easy to implement, and the last one is easy to understand, difficult to implement.",
        text5: "Let's take a look at the 4 agreements we need to make in order to be ourselves and strengthen our individual leadership:",
        text6: "First Covenant: Choose your words carefully",
        text7: "Don Miguel Ruiz underlines that our words have power and that we shape our lives by creating belief patterns with what we say.",
        text8: "Choosing the words you use carefully requires being aware of your feelings and thoughts. Because if you are aware of your feelings and thoughts, you can choose your actions. The ability to choose your actions is a sign that you are not a slave to your emotions and thoughts, so you do not play the victim.",
        text9: "For example, when faced with a danger or threat, we immediately think that if I tell the truth, it may not be good for me, telling the truth will hurt me.",
        text10: "When you have negative thoughts like this, what do you do when you think you have to lie to yourself to protect yourself? What if not telling the truth protects you while blaming an innocent? Or think of times when you know that you are the cause of the problem, but remain silent and others have to take charge of the problem… In our daily relationships, we come across many similar situations in the family or at work. If you were to abide by the first agreement, you would be standing up honestly and boldly without being asked to take responsibility for what you did or did not do!",
        text11: "The difficulty of the first agreement is that you have to come down to your own moral and ethical values. The challenge comes from the need to make choices that honor your own values and act accordingly! Choosing words carefully requires that our feelings, thoughts and behaviors be compatible with our own moral and ethical values. The first deal requires honesty, grace, and dedication.",
        text12: "If we apply the first agreement in our lives, communication problems will decrease, positivity in relationships will increase, and our personal power and effectiveness will increase and we will get rid of our conditioning.",
        text13: "Second Covenant: Don't take anything personally",
        text14: "Don't take it personally when someone insults you, and don't take it personally when someone compliments you! It totally shows the other person's point of view. When we are insulted, our first reaction is to attack, run away, or feel bad about ourselves. When we receive compliments, we can't stop ourselves from feeling good. If you take it personally, you always want to be right, to be approved, to be loved. When you can't find it, you get hurt, you get injured, so you attack. Reacting to both is useless.",
        text15: "However, the actor in your own life should only be you. We have to take this as a feedback and then decide for ourselves what to do.",
        text16: "The important thing is to choose responsibly after this feedback. If you choose the right one for you with confidence, you will have the freedom to distinguish between serving and unhelpful feedback and get what you need. This sets you free!",
        text17: "Everyone who Ruiz calls “conditioning” has their own little right and wrong rulebook. You live your life by your own rules, by your own booklet. If you take what others are doing according to their own conditioning, you will constantly defend yourself with 'buts' or blame others and you will always find yourself in the middle of conflict.",
        text18: "Knowing yourself, accepting yourself as you are and focusing on your own life, regardless of what others think of you, liberates you.",
        text19: "In summary, you are not responsible for the choices, thoughts and actions of others, you are only responsible for your own actions. When you don't take anything personally, you follow your heart, make smart choices and own your choices, and you don't feel guilty or judgmental.",
        text20: "The Third Covenant: Don't Make Assumptions",
        text21: "Look at the decisions you make about your life, the steps you take. Consider your fears and anxieties. How many of these are based on real data and information, and how many of them have you supported with assumptions? Anyway, the work is busy, how many leaves, training etc. so that I can not take leave. Did you keep your request to yourself? Or, how many decisions have you postponed by saying that my wife will be upset and my parents will be affected? Don't make assumptions, go ask, research and take your steps with confidence.",
        text22: "Making assumptions about what has happened and will happen hinders life and consumes energy. This is most common in the face of uncertainty. Assumption comes from taking things personally, placing ourselves in the center of the world, and exaggerating personal importance. The problem with making assumptions is believing your assumptions are true.",
        text23: "For example, the person you love (your mother, your lover) did not call you; We start by saying, “He's bored with me, he wants to get rid of me, he's angry with me” and we make a bunch of assumptions that go all the way to - I'm worthless. Whereas in the above situation, many other assumptions are possible: “He wants to think, he wants time, it's best like that, he needs time alone.” Still, it's best not to make any assumptions. In fact, asking questions to find out the truth, or sharing what you assume with the other person to see if it is valid.",
        text24: "So, is it possible not to make assumptions? We are often unaware that we are making assumptions. One of the effective ways to avoid making assumptions is empathetic listening. If we listen effectively to someone who disagrees with us, we leave ourselves no room for assumptions and judgments. When you really listen to the other person, you have the chance to see what the person is really like, not the person you assume.",
        text25: "The Fourth Agreement: Always do your best",
        text26: "This agreement is the one that allows the other three agreements to become permanent habits.",
        text27: "The fourth deal is the action of the first three. In line with the first three items, wherever, under what conditions, whatever you are doing, do your best. If you are working at the moment, produce the best you can, if you are resting, give the right to rest. When you do justice to the work you do, your self-respect will increase.",
        text28: "Everything is always in flux, so the best of us will not always be the same. The best we do when we are energetic will be different from our best in the tiredness of the evening.",
        text29: "Doing our best under any circumstance frees us from guilt, builds self-respect, and if we can't find that energy, it's best not to do it at all. The big thing is not the small one. If we do a job as perfectly as possible, whether it's cooking, writing articles, or cleaning, you'll find that you have no excuse to judge yourself.",
        text30: "You take action when you always do the best you can. You do every action, every move, every effort because you enjoy it, not because you expect a reward. However, most people do the opposite. He acts only when there is an expectation of reward, but does not enjoy his work. Employees who go to work just for the day they will receive a salary and who are rewarded for their work do not enjoy their work. They want to get the reward by doing as little as possible; this makes their job even more difficult and they cannot reach the pleasure of doing the best they can.",
        text31: "The first three deals only become functional when you do your best. Don't always expect to choose your word carefully, it will take time to change your habits but still do the best you can. Never expect that you will never take anything personally, just do your best. Never expect that you will never make assumptions again. But you can still do your best. When you do your best, your habits of slurring your words, making personal perceptions and assumptions will become weaker and less frequent."
    },
    {
        id: "TR",
        header: "Dört Anlaşma",
        text1: "Don Miguel Ruiz, Dört Anlaşma adlı kitabında Meksika Kızılderilileri tarafından hayata geçirilen Toltek bilgeliğini 4 anlaşma başlığı altında özetliyor. Günümüz yaşam tarzı içerisinde yoğun bir mücadele ve stres altında olan modern insanın aslında özüne dönerek kendisi ile 4 maddelik bir anlaşma yapmasını öneriyor.",
        text2: "Dört Anlaşma halen devam ettiğim liderlik eğitiminde bize tavsiye ettikleri kitaplardan biri. Kendi en iyi halinize ulaşmak istiyorsanız Ruiz’in 4 temel önerisini hayatınıza adapte etmenizi tavsiye ederim. Bu yazı aslında Ruiz’in kitabının kısa bir özetidir. Konuyu tam anlamıyla anlamak için tüm kitabı okumanızı şiddetle tavsiye ederim. Ben özellikle kariyer yapmak ve liderlik yolculuğunda başarılı olmak için neyin önemli olduğuna dair çok soru alıyorum. Liderliğe giden yolda sihirli bir formül olduğunu düşünmemekle beraber liderliğin “kişilikle” doğrudan alakalı olduğunu ve kendi en iyi halinize ulaşmanın kariyer gelişiminize destek olacağını düşünüyorum.",
        text3: "Büyürken farkında olmadan toplumsal ve aile içindeki şartlanmalarla binlerce anlaşmalar yaptık. Bu anlaşmaların yerine geçecek olan dört yeni anlaşmayı uygulayabilirsek değişim başlar. Eğer olumlu ve mutlu bir yaşam sürmek istiyorsak, korku temelli anlaşmalarınızı feshetmek ve sevgi temelli anlaşmaları hayatımıza yerleştirmek zorundayız. Korku temelli anlaşmalar sizin enerjinizi tüketmekle meşgulken, bireysel gücünüze sahip çıkabilmeniz mümkün değil. Bu dört sevgi temelli anlaşmayı yapabilirseniz bireysel gücünüze sahip çıkabilirsiniz.",
        text4: "Ruiz’in ilkelerini hayatınıza uygulamak hem zor hem kolay. Birinci anlaşma anlaması ve uygulaması kolay. İkincisi anlaması ve uygulaması zor. Üçüncü anlaşma anlaması zor uygulaması kolay, sonuncusu ise anlaması kolay, uygulaması zor.",
        text5: "Haydi, gelin kendimiz olmak, bireysel liderliğimizi güçlendirmek için yapmamız gereken 4 anlaşmaya tek tek göz atalım:",
        text6: "Birinci Anlaşma: Kullandığın sözcükleri özenle seç",
        text7: "Don Miguel Ruiz, sözlerimizin gücü olduğunu ve söylediklerimizle birlikte aslında inanç kalıpları yaratarak yaşantımızı şekillendirdiğimizin altını çiziyor.",
        text8: "Kullandığınız sözcükleri özenle seçmek duygularınızın ve düşüncelerinizin farkında olmayı gerektirir. Çünkü duygularınızın ve düşüncelerinizin farkında olursanız davranışlarınızı seçebilirsiniz. Davranışlarınızı seçebilme becerisi ise duygularınızın ve düşüncelerinizin kölesi olmadığınızın işaretidir, yani kurban rolü oynamazsınız.",
        text9: "Örneğin bir tehlike ya da tehditle karşı karşıya kaldığınızda hemen eğer gerçeği söylersem benim için iyi olmayabilir, gerçeği söylemek bana zarar verir diye düşünürüz.",
        text10: "Buna benzer olumsuz düşüncelere kapıldığınızda kendinizi korumak için kendinize yalan söylemeniz gerektiğini düşündüğünüzde ne yapıyorsunuz? Ya gerçeği söylememek sizi korurken masum birini suçluyorsa? Ya da problemin sebebinin kendiniz olduğunu bildiğiniz halde sessiz kaldığınız ve başkalarının sorunu üstlenmek durumunda kaldığı anları düşünün… Günlükilişkilerimizde ailede ya da işte benzer bir sürü durumla karşılaşıyoruz. Eğer birinci anlaşmaya uyuyor olsanız size sorulmadan dürüstçe ve cesurca ayağa kalkıp yaptıklarınızın ya da yapmadıklarınızın sorunluluğunu sahipleniyor olursunuz!",
        text11: "Birinci anlaşmanın zorluğu kendi ahlak ve etik değerlerinize inmeniz gerekiyor. Zorluk kendi değerlerinizi onurlandıracak seçimler yapmak ve ona uygun davranışları göstermek gerekliliğinden kaynaklanıyor! Sözcükleri özenle seçmek duygu, düşünce ve davranışlarımızın kendi ahlak ve etik değerlerimizle uyumlu olmasını gerektirir. Birinci anlaşma dürüstlük, zarafet ve kendini adamayı gerektirir.",
        text12: "Birinci anlaşmayı hayatımızda uygularsak iletişim problemleri azalır, ilişkilerde olumluluk artar ve kişisel gücümüz ve etkililiğimiz artarak koşullanmışlıklarımızdan kurtuluruz.",
        text13: "İkinci Anlaşma: Hiçbir şeyi kişisel algılama",
        text14: "Biri size hakaret ettiğinde kişisel almayın, aynı şekilde biri size iltifat ettiğinde de kişisel almayın! Bu tamamen karşınızdakinin bakış açısını gösterir. Bize hakaret edildiğinde ilk tepkimiz aynı şekilde saldırmak, kaçmak ya da kendimizi kötü hissetmek şeklinde olur. İltifat alınca da kendimizi iyi hissetmekten alıkoyamayız. Kişisel algılarsanız, hep haklı çıkmak, onaylanmak, sevilmek istersiniz. Bunu bulamadığınızda ise incinir, yara alır dolayısıyla saldırırsınız. Her ikisine de tepki vermek faydasız.",
        text15: "Oysaki kendi hayatınızdaki aktör, sadece siz olmalısınız. Bunu bir geribildirim olarak alıp sonra ne yapacağımıza kendimiz karar vermeliyiz.",
        text16: "Önemli olan bu geribildirimden sonra sorumlu bir şekilde seçim yapmak. Kendinize güvenerek sizin için doğru olanı seçerseniz size hizmet eden/etmeyen geribildirimleri ayırt edip ihtiyacınız olanı alma özgürlüğüne sahip olursunuz. Bu sizi özgür kılar!",
        text17: "Ruiz in “şartlanma” dediği herkesin kendi küçük doğru yanlışı gösteren kural kitapçıkları var. Siz hayatınızı kendi kurallarınıza, kendi kitapçığınıza göre yaşayın. Eğer başkalarının kendi şartlanmalarına göre yaptıklarını kişisel algılarsanız sürekli “ama”larla kendinizi savunur ya da başkalarını suçlarsınız ve kendinizi hep çatışmanın ortasında bulursunuz.",
        text18: "Kendinizi tanımak, kendinizi her halinizle kabul etmek başkalarının sizinle ilgili ne düşündüğüne bakmaksızın kendi hayatınıza odaklanmak sizi özgürleştirir.",
        text19: "Özetle başkalarının seçimleri, düşünceleri ve davranışlarından sorumlu değilsiniz sadece kendi davranışlarınızdan sorumlusunuz. Hiçbir şeyi kişisel almadığınızda kalbinizi takip edip, akıllı seçimler yapıp seçimlerinize sahip çıkar, kendinizi yargılayıp suçlu hissetmezsiniz.",
        text20: "Üçüncü Anlaşma: Varsayımda bulunmayın",
        text21: "Hayatınızla ilgili aldığınız kararlara, attığınız adımlara bakın. Korku ve kaygılarınızı düşünün. Bunların ne kadarı gerçek veri ve bilgileredayanıyor, ne kadarını varsayımlarla desteklediniz? Nasıl olsa işler yoğun, izin alamam diye kaç izin, eğitim vs. talebinizi kendinize sakladınız? Veya eşim üzülür, annem babam etkilenir diyerek kaç kararınızı ertelediniz? Varsayımda bulunmayın, gidin sorun, araştırın ve adımlarınızı emin olarak atın.",
        text22: "Olmuş ve olacaklar hakkında varsayımlarda bulunmak, yaşamayı engeller ve enerjiyi tüketir. Bu durum en çok belirsizlikler karşısında yaşanır. Varsayım, her şeyi kişisel algılamak, dünyanın merkezine kendimizi oturtmak ve kişisel önemi abartmak sonucu oluşur. Varsayımda bulunmanın problemi, varsayımlarınızın gerçek olduğuna inanmaktır.",
        text23: "Örneğin sevdiğiniz kişi (anneniz, sevgiliniz) sizi aramadı; “Benden sıkıldı, kurtulmak istiyor, bana kızgın” diye başlar ve “Ben değersizim” e kadar uzanan bir sürü varsayımda bulunuruz. Oysa yukarıdaki durumda bir sürü başka varsayım da mümkündür: “Düşünmek istiyor, zaman istiyor, böylesi en iyisi, yalnız kalmaya ihtiyacı vardır.” Yine de en iyisi hiç varsayımda bulunmamaktır. Doğrusu gerçeği öğrenmek için soru sormak ya da varsaydığınız şeyi karşınızdaki ile paylaşıp geçerli olup olmadığını öğrenmek.",
        text24: "Peki, varsayımda bulunmamak mümkün müdür? Çoğunlukla varsayımda bulunduğumuzun farkında değilizdir. Varsayımda bulunmamak için etkili yöntemlerden biri empatik dinlemedir. Bizimle aynı fikirde olmayan birini etkili bir şekilde dinlersek kendimize varsayım yapacak ve yargılayacak alan bırakmamış oluruz. Karşınızdakini gerçekten dinlediğinizde o kişinin varsaydığınız gibi bir kişi değil de gerçekten nasıl biri olduğunu görme şansınız olur.",
        text25: "Dördüncü Anlaşma: Her zaman elinizden gelenin en iyisini yapın",
        text26: "Bu anlaşma diğer üç anlaşmanın kalıcı alışkanlığa dönüşmesini sağlayananlaşmadır.",
        text27: "Dördüncü anlaşma ilk üçün aksiyonudur. İlk üç madde doğrultusunda nerede, hangi şartlarda, ne yapıyor olursanız olun en iyisini yapın. O anda çalışıyorsanız elinizden gelenin en iyisini üretin, dinleniyorsanız dinlenmenin hakkını verin. Yaptığınız işin hakkını verdiğiniz zaman kendinize duyduğunuz saygı da artacaktır.",
        text28: "Her şey her an değişim halindedir dolayısıyla en iyimiz her zaman aynı olmayacaktır. Enerjik iken yaptığınız en iyimiz akşamın yorgunluğundaki en iyimizden farklı olacaktır.",
        text29: "Her koşul altında en iyisini yapmak, bizi suçluluk duygusundan kurtarır, kendimize saygı duymanızı sağlar ve bu enerjiyi bulamıyorsak da hiç yapmamak en iyisidir. İşin büyüğü küçüğü olmaz. Yemek pişirirken de, makale yazarken de, temizlik yaparken de bir işi olabildiğince kusursuz yaparsak kendinizi yargılamanız için mazeretiniz kalmadığını görürsünüz.",
        text30: "Yapabildiğinizin daima en iyisini yaptığınızda harekete geçersiniz. Her eylemi, her hareketi, her çabayı zevk aldığınız için yaparsınız, bir ödül beklediğiniz için değil. Oysa çoğu insan bunun tersini yapar. Sadece bir ödül beklentisi olduğunda harekete geçer ama yaptığı işten zevk almaz. Sadece maaş alacağı gün uğruna işe giden, yaptığı işin karşılığı aldığı ödül için çalışanlar yaptığı işten zevk almazlar. Mümkün olduğunca az şey yaparak ödülü almak isterler; bu da işlerini iyice zorlaştırır ve yapabileceklerinin en iyisini yapma hazzına ulaşamazlar.",
        text31: "İlk üç anlaşma ancak yapabildiğinizin en iyisini yapabildiğiniz de işlevsel hale gelir. Daima, sözünüzü özenle seçmeyi beklemeyin, alışkanlıklarınızı değiştirmek zaman alacaktır ama yine de yapabileceğinizin en iyisini yapın. Hiçbir şeyi asla kişisel algılamayacağınızı beklemeyin, sadece en iyisini yapın. Asla bir daha varsayımda bulunmayacağınızı beklemeyin. Ama yine de en iyisini yapabilirsiniz. Yapabildiğinizin en iyisini yaptığınızda sözlerinizi özensiz kullanma, kişisel algılama ve varsayımlarda bulunma alışkanlıklarınız gittikçe zayıflayacak ve seyrekleşecektir."
    }
];

class DortAnlasma extends Component {
    componentDidMount() {
        const text = `DortAnlasma: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });
        
        return (
            <div>
                <div className="PageItemHeader">
                    {lItem.header}
                </div>
                <div className="PageText">
                    <p>{lItem.text1}</p>
                    <img
                        src={DortAnlasmaImg}
                        alt="Dört Anlaşma"
                        width="40%"
                        height="auto"
                        align="left"
                        hspace="20"
                        vspace="5"
                    />
                    <p>{lItem.text2}</p>
                    <p>{lItem.text3}</p>
                    <p>{lItem.text4}</p>
                    <p>{lItem.text5}</p>
                    <p>{lItem.text6}</p>
                    <p>{lItem.text7}</p>
                    <p>{lItem.text8}</p>
                    <p>{lItem.text9}</p>
                    <p>{lItem.text10}</p>
                    <p>{lItem.text11}</p>
                    <p>{lItem.text12}</p>
                    <p>{lItem.text13}</p>
                    <p>{lItem.text14}</p>
                    <p>{lItem.text15}</p>
                    <p>{lItem.text16}</p>
                    <p>{lItem.text17}</p>
                    <p>{lItem.text18}</p>
                    <p>{lItem.text19}</p>
                    <p>{lItem.text20}</p>
                    <p>{lItem.text21}</p>
                    <p>{lItem.text22}</p>
                    <p>{lItem.text23}</p>
                    <p>{lItem.text24}</p>
                    <p>{lItem.text25}</p>
                    <p>{lItem.text26}</p>
                    <p>{lItem.text27}</p>
                    <p>{lItem.text28}</p>
                    <p>{lItem.text29}</p>
                    <p>{lItem.text30}</p>
                    <p>{lItem.text31}</p>
                    <p>
                    Vicdan Merter CPCC, PCC, ACTC
                    </p>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(DortAnlasma);