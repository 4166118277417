import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from '../actions/account';

import PageItem from '../PageItem';

import CheckIn from './CheckIn';
import Liderlik from './Liderlik';
import Mahser from './Mahser';
import Farkindalik from './Farkindalik';
import DortAnlasma from './DortAnlasma';
import Kurban from './Kurban';
import Mola from './Mola';

const lList = [
    {
        id: "EN",
        header: "Articles",
        itemHeader1: "Let's Check In",
        itemText1: "When we come together with teams for workshops, team coaching or training, the first thing we do is CHECK-IN. With the English version ...",
        itemHeader2: "Everyone is a Leader",
        itemText2: "Leadership is a very important issue for the business world and organizations. As there are multiple definitions of leadership, there are also different ...",
        itemHeader3: "The Four Horsemen",
        itemText3: "The Four Horsemen of the Apocalypse are the 4 horsemen mentioned in the Bible, believed to appear before the apocalypse and marking the end of the ...",
        itemHeader4: "Self Awareness",
        itemText4: "In this article, I will tell you about individual awareness, which is one of the most important steps in the path of leadership, and how it can be developed ...",
        itemHeader5: "The Four Agreements",
        itemText5: "In his book The Four Agreements, Don Miguel Ruiz describes the Toltec wisdom brought to life by the Mexican Indians under the title of 4 agreements ...",
        itemHeader6: "It's not my fault!",
        itemText6: "There is a concept that I hear a lot these days. 'Victim Behavior' Let's try to understand together what it is. Victim Psychology, on our own ...",
        itemHeader7: "Taking a break",
        itemText7: "You remember the school period, we used to take breaks between classes and run away from all our stress, boredom and inactivity. Adult ...",
        text1: "Back"
    },
    {
        id: "TR",
        header: "Yazılar",
        itemHeader1: "Check-In Yapalım",
        itemText1: "Takımlarla workshop, takım koçluğu ya da eğitim için bir araya geldiğimizde ilk yaptığımız CHECK-IN yapmak olur.  İngilizce hali ile ...",
        itemHeader2: "Herkes Liderdir",
        itemText2: "İş dünyası ve organizasyonlar için liderlik çok önemli bir konu. Liderliğin birden çok tanımı olduğu gibi farklı sayıda liderlik stilleri de mevcut. Çok ...",
        itemHeader3: "Mahşerin Dört Atlısı",
        itemText3: "Mahşerin Dört Atlısı, İncil’de adları geçen, kıyamet öncesinde ortaya çıkacaklarına inanılan ve dünyanın sonunu işaret eden 4 atlı. Sırasıyla yenilgi ...",
        itemHeader4: "Bireysel Farkındalık",
        itemText4: "Bu yazımda size liderlik yolunda önemli basamaklardan biri olan hatta en önemlisi diyebileceğim bireysel farkındalıktan ve nasıl geliştirilebileceğinden ...",
        itemHeader5: "Dört Anlaşma",
        itemText5: "Don Miguel Ruiz, Dört Anlaşma adlı kitabında Meksika Kızılderilileri tarafından hayata geçirilen Toltek bilgeliğini 4 anlaşma başlığı ...",
        itemHeader6: "Benim Hatam Değil!",
        itemText6: "Bu aralar çok sık duyduğum bir kavram var. 'Kurban Davranışı' Gelin hep birlikte ne olduğunu anlamaya çalışalım. Kurban Psikolojisi, başımıza ...",
        itemHeader7: "Mola Vermek",
        itemText7: "Okul döneminden hatılarsınız, ders aralarında teneffüse çıkar ve bütün stresimizi, can sıkıntımızı, hareketsizliğimizi koşturarak atardık. Yetişkin ...",
        text1: "Geri Dön"
    }
];

class Yazilar extends Component {
    state = {
        detayGoster: 'None'
    }

    componentDidMount() {
        const text = `Yazilar: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    handleClick = () => {
        this.setState({ detayGoster: 'None' });
    }

    callPage = (detay) => {
        this.setState({ detayGoster: detay });
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });
        
        return (       
            <div className="Page">
                <div className="PageHeader">
                    {lItem.header}
                </div>
                {this.state.detayGoster === 'None' ?
                    <div className="Page">
                        <PageItem
                            Image="CheckIn"
                            Header={lItem.itemHeader1}
                            shortText={lItem.itemText1}
                            callPage={this.callPage} />
                        <PageItem
                            Image="Liderlik"
                            Header={lItem.itemHeader2}
                            shortText={lItem.itemText2}
                            callPage={this.callPage} />
                        <PageItem
                            Image="Mahser"
                            Header={lItem.itemHeader3}
                            shortText={lItem.itemText3}
                            callPage={this.callPage} />
                        <PageItem
                            Image="Farkindalik"
                            Header={lItem.itemHeader4}
                            shortText={lItem.itemText4}
                            callPage={this.callPage} />
                        <PageItem 
                            Image="DortAnlasma"
                            Header={lItem.itemHeader5}
                            shortText={lItem.itemText5}
                            callPage={this.callPage} />
                        <PageItem 
                            Image="Kurban"
                            Header={lItem.itemHeader6}
                            shortText={lItem.itemText6}
                            callPage={this.callPage} />
                        <PageItem 
                            Image="Mola"
                            Header={lItem.itemHeader7}
                            shortText={lItem.itemText7}
                            callPage={this.callPage} />
                    </div>
                :
                    <div>
                        {
                            this.state.detayGoster === 'CheckIn' ? <CheckIn language={this.props.language} /> :
                            this.state.detayGoster === 'Liderlik' ? <Liderlik language={this.props.language} /> :
                            this.state.detayGoster === 'Mahser' ? <Mahser language={this.props.language} /> :
                            this.state.detayGoster === 'Farkindalik' ? <Farkindalik language={this.props.language} /> :
                            this.state.detayGoster === 'DortAnlasma' ? <DortAnlasma language={this.props.language} /> :
                            this.state.detayGoster === 'Kurban' ? <Kurban language={this.props.language} /> :
                            this.state.detayGoster === 'Mola' ? <Mola language={this.props.language} /> :
                            <div>404</div>
                        }
                        <div className="CAButton" onClick={this.handleClick}>
                            {lItem.text1}
                        </div>
                    </div>
                } 
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Yazilar);